import { httpsCallable, fb_function } from '../firebaseConfig';
const validateRecaptcha = httpsCallable(fb_function, 'validateRecaptcha');

export const postRecaptchaDetails = async (captchaDetails) => {
  try {
    const response = await validateRecaptcha(captchaDetails);
    return response.data;
  } catch (error) {
    return error;
  }
};