import React from 'react';
import { Link } from 'react-router-dom';
import './PageNotFound.css'; // Custom CSS file

const PageNotFound = () => {
  return (
    <div className="page-not-found-container">
      <div className="page-not-found-content">
        <h1 className="page-not-found-title">404</h1>
        <h2 className="page-not-found-subtitle">Oops! Page Not Found</h2>
        <p className="page-not-found-message">
          The page you are looking for might have been removed or is temporarily
          unavailable.
        </p>
        <Link to="/" className="page-not-found-back-home-btn">
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
