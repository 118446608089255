import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ContactUs from '../HomePage/ContactUs/ContactUs';
import PageBanner from '../PageBanner/PageBanner';
import ContactImage from '../../Assets/Images/contactUs.jpg';

const ContactUsPage = () => {
  return (
    <>
      <Header />
      <PageBanner
        title={'Connect with Our Team'}
        description={
          "We're here to help—reach out to us for any inquiries or support."
        }
        image={ContactImage}
      />
      <ContactUs />
      <Footer />
    </>
  );
};

export default ContactUsPage;
