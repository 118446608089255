import React from 'react';
import './PrivacyPolicy.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className="rtc-privacy-container">
        <h1 className="page-title">Privacy Policy</h1>
        <p>
          Effective Date:{' '}
          <span className="policy-update-date">06-sep-2024</span>
        </p>
        <section className="rtc-privacy-section">
          <h2 className="rtc-privacy-heading">Introduction</h2>
          <p className="rtc-privacy-text">
            At RuhiTech Solutions, we are committed to protecting your privacy.
            This Privacy Policy outlines the types of personal information we
            collect, how we use it, and the measures we take to safeguard your
            data.
          </p>
        </section>

        <section className="rtc-privacy-section">
          <h2 className="rtc-privacy-heading">Information We Collect</h2>
          <p className="rtc-privacy-text">
            We may collect personal information such as your name, email
            address, phone number, and payment details during the course
            registration process. We also collect information related to your
            participation in our courses, such as attendance records and
            performance data.
          </p>
        </section>

        <section className="rtc-privacy-section">
          <h2 className="rtc-privacy-heading">How We Use Your Information</h2>
          <p className="rtc-privacy-text">
            The information we collect is used to provide and improve our
            services. This includes processing your course registration,
            managing your participation, and communicating with you about course
            updates. We may also use your information to send you marketing
            communications, which you can opt out of at any time.
          </p>
        </section>

        <section className="rtc-privacy-section">
          <h2 className="rtc-privacy-heading">Sharing Your Information</h2>
          <p className="rtc-privacy-text">
            RuhiTech Solutions does not share, sell, or rent your personal
            information to third parties for their marketing purposes. We may
            share your information with trusted partners who assist us in
            operating our website, conducting our business, or servicing you, as
            long as those parties agree to keep your information confidential.
          </p>
        </section>

        <section className="rtc-privacy-section">
          <h2 className="rtc-privacy-heading">Data Security</h2>
          <p className="rtc-privacy-text">
            We implement a variety of security measures to protect your personal
            information from unauthorized access, use, or disclosure. However,
            no method of transmission over the internet or method of electronic
            storage is 100% secure, and we cannot guarantee absolute security.
          </p>
        </section>

        <section className="rtc-privacy-section">
          <h2 className="rtc-privacy-heading">Your Rights</h2>
          <p className="rtc-privacy-text">
            You have the right to access, correct, or delete your personal
            information that we hold. You can also object to the processing of
            your personal data in certain circumstances. To exercise these
            rights, please contact us at contact@ruhitechsolutions.com.
          </p>
        </section>

        <section className="rtc-privacy-section">
          <h2 className="rtc-privacy-heading">
            Changes to This Privacy Policy
          </h2>
          <p className="rtc-privacy-text">
            RuhiTech Solutions reserves the right to update or change this
            Privacy Policy at any time. We will notify you of any significant
            changes by posting the new policy on our website. Your continued use
            of our services after any changes to this policy constitutes your
            acceptance of the updated terms.
          </p>
        </section>

        <section className="rtc-privacy-section">
          <h2 className="rtc-privacy-heading">Contact Us</h2>
          <p className="rtc-privacy-text">
            If you have any questions or concerns about this Privacy Policy,
            please contact us at:
            <br />
            Email: contact@ruhitechsolutions.com
            <br />
            Phone: +91-8220343006
            <br />
            Address: 89/15, 1st Main Road, Gandhi Nagar, Adyar, Chennai-600020
          </p>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
