import React, { useState } from 'react';
import './BlogsPage.css';
import { blogs } from '../../ModelData/ModelData';

const BlogPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 5;

  // Calculate pagination values
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="blog-list">
      <ul className="blog-items">
        {currentBlogs.map((blog, index) => (
          <li key={index} className="blog-item">
            <div className="blog-title-section">
              <img
                src={blog.imageUrl}
                alt={blog.title}
                className="blog-image"
              />
              <h3 className="blog-title">{blog.title}</h3>
              <p className="blog-excerpt">{blog.description}</p>
            </div>

            <div>
              <a
                className="read-more-link"
                href={`/blogPost?blogId=${blog.blogId}`}
              >
                Read More
              </a>
            </div>
          </li>
        ))}
      </ul>
      <div className="pagination">
        {Array.from(
          { length: Math.ceil(blogs.length / blogsPerPage) },
          (_, i) => (
            <button
              key={i + 1}
              onClick={() => paginate(i + 1)}
              className={currentPage === i + 1 ? 'active' : ''}
            >
              {i + 1}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default BlogPage;
