import React from 'react';
import './MissionVision.css';

const MissionVision = () => {
  return (
    <>
      <div className="content-wrapper-mission">
        <div className="content-block mission">
          <div className="content-section-mission">
            <h2 className="content-title">Our Mission</h2>
            <p className="content-text">
              To empower individuals with the skills and knowledge they need to
              excel in the technology industry.
            </p>
          </div>
        </div>
      </div>
      <div className="content-wrapper-vision">
        <div className="content-block vision">
          <div className="content-section-vision">
            <h2 className="content-title">Our Vision</h2>
            <p className="content-text">
              To be a leading institution in technology education, fostering
              innovation and creativity for a better future.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MissionVision;
