import React from 'react';
import { useSearchParams } from 'react-router-dom';
import PageBanner from '../../PageBanner/PageBanner';
import CourseSyllabus from '../CourseSyllabus/CourseSyllabus';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import {syllabusTopics} from '../courseModel'
import CourseOutline from '../CourseOutline/CourseOutline'
import CourseInfoSection from '../CourseInfoSection/CourseInfoSection'
import FeedbackSection from '../FeedbackSection/FeedbackSection'
import CourseAdvantages from '../CourseAdvantages/CourseAdvantages'
import CompanyLogos from '../CompanyLogos/CompanyLogos'
import { FaBuilding, FaCogs, FaBriefcase } from 'react-icons/fa';
import ImportanceComponent from '../ImportanceComponent/ImportanceComponent'
import { useDispatch } from 'react-redux';
import { openModal } from '../../../reducers/modalSlice';
import './CourseLanding.css'

const CourseLandingPage = () => {
  const [searchParams] = useSearchParams();
  const currentCourse = searchParams.get('courseName');
  const selectedCourse = syllabusTopics[currentCourse]
  const dispatch = useDispatch();

  const points = [
    {
      icon: <FaBuilding />,
      title: 'Widely Adopted by Enterprises',
      description: 'Angular is widely used by enterprises like Google, Microsoft, and IBM to create large-scale web applications.'
    },
    {
      icon: <FaCogs />,
      title: 'Powerful Framework',
      description: 'Angular provides a robust framework for building dynamic single-page applications with ease and scalability.'
    },
    {
      icon: <FaBriefcase />,
      title: 'Career Opportunities',
      description: 'Skilled Angular developers are in demand for enterprise-level projects, with strong job opportunities globally.'
    }
  ];

  return (
    <>
      <Header />
      {selectedCourse && (
        <>
          <PageBanner
            title={selectedCourse.title}
            description={selectedCourse.description}
            image={selectedCourse.image}
            buttonText={selectedCourse.buttonText}
          />
          <CourseOutline skills={selectedCourse.skills} />
          <CourseInfoSection courseInfo={selectedCourse.courseInfo}/>
          <CourseSyllabus syllabusTopics={syllabusTopics} selectedCourse={selectedCourse}/>
          <button
            className="course-enroll-button"
            onClick={() => dispatch(openModal(selectedCourse.title))}
          >
            Enroll Now
          </button>
          <CourseAdvantages />
          {currentCourse === 'react' &&
          <>
          <ImportanceComponent title="Why Learn React?" points={points} />
          <FeedbackSection feedbacks={selectedCourse.feedbackData} />
          <CompanyLogos companies={selectedCourse.companies} />
          </>
          }
        </>
      )}
      <Footer />
    </>
  );
};

export default CourseLandingPage;
