import expressImage from '../../Assets/Images/courses/frontendBasics.jpg';
import frontendImage from '../../Assets/Images/courses/frontendMaster.jpg';
import backEndImage from '../../Assets/Images/courses/backendMaster.jpg';
import fullStackImage from '../../Assets/Images/courses/fullStackDevelopment.jpg';
import digitalMarketingImage from '../../Assets/Images/courses/digitalMarketing.jpg';
import manualTestingImage from '../../Assets/Images/courses/manualTesting.jpg';
import automationTestingImage from '../../Assets/Images/courses/automationTesting.jpg';
import angularjsImage from '../../Assets/Images/courses/angularjs.jpg';
import reactjsImage from '../../Assets/Images/courses/reactjs.svg';
import reactNativeImage from '../../Assets/Images/courses/reactnative.jpg';
import nodejsImage from '../../Assets/Images/courses/nodejs.jpg';
import javaImage from '../../Assets/Images/courses/java.jpg';
import pythonImage from '../../Assets/Images/courses/python.jpg';
import dataScienceImage from '../../Assets/Images/courses/datascience.jpg';
import machineLearningImage from '../../Assets/Images/courses/machinelearning.jpg';
import promptEngineeringImage from '../../Assets/Images/courses/prompt.jpg';
import generativeAIImage from '../../Assets/Images/courses/genAi.jpg';
import javascriptImage from '../../Assets/Images/courses/javascript.jpg';



export const syllabusTopics = {
    frontendMaster: {
      title: 'Frontend Master',
      description: 'Learn extensive frontend technologies including HTML, CSS, JavaScript, React, and modern frameworks to build interactive user interfaces.',
      icon: 'FaCode', // You can choose a suitable icon for Frontend Master
      image: frontendImage, // Add the appropriate image here
      buttonText: 'Enroll Now',
      hours: '58',
      link: '/courseDetails?courseName=frontendMaster',
      syllabus:[],
      technologies: ['html','css','javascript','bootstrap','react','typescript'],
      showCourse: true,
      skills: [
        'Mastering HTML5 and CSS3 for modern web development',
        'Understanding JavaScript and DOM manipulation',
        'Building responsive layouts using CSS frameworks',
        'Creating dynamic and interactive user interfaces',
        'Mastering React for building reusable components and SPAs',
        'Utilizing modern JavaScript frameworks (React, Angular)',
        'Optimizing frontend performance and accessibility'
      ],
      courseInfo: [
        {
          iconClass: 'fas fa-laptop-code',
          label: '6+ Skills',
        },
        {
          iconClass: 'fas fa-clock',
          label: 'Approximately 58 hours',
        },
        {
          iconClass: 'fas fa-user-check',
          label: 'Beginner to Advanced',
        },
      ],
      companies: [
        { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
        { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
        { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
      ]
    },
    backendMaster: {
      title: 'Backend Master',
      description: 'Master backend technologies including Node.js, Express, and MongoDB to build powerful and scalable server-side applications.',
      icon: 'FaServer', // You can choose a suitable icon for Backend Master
      image: backEndImage, // Add the appropriate image here
      buttonText: 'Enroll Now',
      hours: '50',
      link: '/courseDetails?courseName=backendMaster',
      syllabus: [], // Add syllabus details here as needed
      technologies: ['nodejs','express','mongodb','typescript'],
      showCourse: true,
      skills: [
        'Mastering Node.js for server-side development',
        'Building RESTful APIs with Express.js',
        'Managing databases with MongoDB for NoSQL data storage',
        'Implementing authentication and authorization',
        'Working with asynchronous programming in JavaScript',
        'Understanding API design and development best practices',
        'Optimizing backend performance and security'
      ],
      courseInfo: [
        {
          iconClass: 'fas fa-server',
          label: '4+ Skills',
        },
        {
          iconClass: 'fas fa-clock',
          label: 'Approximately 50 hours',
        },
        {
          iconClass: 'fas fa-user-check',
          label: 'Beginner to Advanced',
        },
      ],
      companies: [
        { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
        { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
        { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
      ]
    },
    mernStackMaster: {
      title: 'MERN Stack Master',
      description: 'Become a full-stack developer by mastering MongoDB, Express, React, and Node.js to build dynamic, scalable web applications.',
      icon: 'FaLayerGroup', // You can choose a suitable icon for MERN Stack Master
      image: fullStackImage, // Add the appropriate image here
      buttonText: 'Enroll Now',
      hours: '90',
      link: '/courseDetails?courseName=mernStackMaster',
      syllabus: [], // Add syllabus details here as needed
      skills: [
        'Mastering full-stack development with the MERN stack (MongoDB, Express, React, Node.js)',
        'Building powerful RESTful APIs with Express and Node.js',
        'Developing dynamic and interactive frontend interfaces with React',
        'Managing data with MongoDB for NoSQL database solutions',
        'Handling asynchronous programming in JavaScript for both frontend and backend',
        'Integrating frontend and backend for a seamless user experience',
        'Deploying and scaling full-stack applications'
      ],
      technologies: ['html','css','javascript','bootstrap','react','nodejs','express','mongodb','typescript'],
      showCourse: true,
      courseInfo: [
        {
          iconClass: 'fas fa-layer-group',
          label: '10+ Skills',
        },
        {
          iconClass: 'fas fa-clock',
          label: 'Approximately 90 hours',
        },
        {
          iconClass: 'fas fa-user-check',
          label: 'Beginner to Advanced',
        },
      ],
      companies: [
        { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
        { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
        { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
      ]
    },
    html: {
      title: 'HTML',
      description: 'This course provides the fundamental skills needed to build responsive websites using HTML',
      icon: 'FaHtml5',
      image: '',
      buttonText: 'Enroll Now',
      hours: '',
      link: '',
      syllabus: [
        { title: 'Introduction to HTML', hours: 0, minutes: 10 },
        { title: 'HTML Document Structure', hours: 0, minutes: 15 },
        { title: 'Basic Text Formatting', hours: 0, minutes: 10 },
        { title: 'HTML Headings and Paragraphs', hours: 0, minutes: 10 },
        { title: 'Lists: Ordered, Unordered, and Definition', hours: 0, minutes: 10 },
        { title: 'Tables in HTML', hours: 0, minutes: 15 },
        { title: 'Hyperlinks and Navigation', hours: 0, minutes: 10 },
        { title: 'HTML Images and Multimedia', hours: 0, minutes: 15 },
        { title: 'Forms and Input Elements', hours: 0, minutes: 20 },
        { title: 'Semantic HTML Elements', hours: 0, minutes: 10 },
        { title: 'HTML Comments and Meta Information', hours: 0, minutes: 10 },
        { title: 'HTML5 New Elements', hours: 0, minutes: 15 },
        { title: 'Embedding Videos and Audio', hours: 0, minutes: 10 },
        { title: 'Responsive Images with <picture> Element', hours: 0, minutes: 10 },
        { title: 'HTML Best Practices', hours: 0, minutes: 15 },
        { title: 'SEO and HTML', hours: 0, minutes: 15 },
        { title: 'Accessibility in HTML', hours: 0, minutes: 10 },
        { title: 'Integrating CSS with HTML', hours: 0, minutes: 15 },
        { title: 'HTML in Practice: Structuring a Complete Web Page', hours: 0, minutes: 30 },
        { title: 'Final Project: Building a Multi-page Website', hours: 0, minutes: 30 },
      ],
      showCourse: false,
      skills: [
        'Understanding HTML Structure and Semantic HTML',
        'Creating Forms and Input Elements for User Interaction',
        'Building Web Navigation using Hyperlinks',
        'Embedding Multimedia Elements like Images and Videos',
        'Using Advanced HTML5 Features like APIs',
        'Optimizing Web Pages for Performance and SEO'
      ],
      courseInfo: [
        {
          iconClass: 'fas fa-laptop-code',
          label: '6 Skills',
        },
        {
          iconClass: 'fas fa-clock',
          label: '13 hours 25 minutes',
        },
        {
          iconClass: 'fas fa-user',
          label: 'Intermediate',
        },
      ],
      companies : [
        { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
        { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
        { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
      ]
    },
    css: {
        title: 'CSS',
        description: 'This course covers the fundamental skills needed to style and layout web pages using CSS.',
        icon: 'FaCss3Alt',
        image: '',
        buttonText: 'Enroll Now',
        hours: '',
        link: '',
        syllabus: [
          { title: 'Introduction to CSS', hours: 0, minutes: 10 },
          { title: 'CSS Syntax and Selectors', hours: 0, minutes: 15 },
          { title: 'CSS Box Model', hours: 0, minutes: 20 },
          { title: 'CSS Flexbox', hours: 0, minutes: 30 },
          { title: 'CSS Grid Layout', hours: 0, minutes: 30 },
          { title: 'Styling Text and Fonts', hours: 0, minutes: 15 },
          { title: 'CSS Colors and Backgrounds', hours: 0, minutes: 15 },
          { title: 'CSS Borders and Shadows', hours: 0, minutes: 15 },
          { title: 'CSS Positioning', hours: 0, minutes: 20 },
          { title: 'Responsive Design with Media Queries', hours: 0, minutes: 30 },
          { title: 'CSS Transitions and Animations', hours: 0, minutes: 20 },
          { title: 'CSS Best Practices', hours: 0, minutes: 15 },
          { title: 'CSS Preprocessors (e.g., SASS, LESS)', hours: 0, minutes: 20 },
          { title: 'CSS in Practice: Building a Styled Web Page', hours: 0, minutes: 30 },
          { title: 'Final Project: Styling a Multi-page Website', hours: 1, minutes: 0 },
        ],
        showCourse: false,
        skills: [
            'Understanding CSS Syntax and Selectors for styling web pages',
            'Mastering the CSS Box Model for layout design',
            'Using Flexbox and Grid for responsive layouts',
            'Styling text and fonts to enhance visual appeal',
            'Implementing responsive design techniques with media queries',
            'Creating transitions and animations to improve user experience'
          ],
          courseInfo: [
            {
              iconClass: 'fas fa-laptop-code',
              label: '6 Skills',
            },
            {
              iconClass: 'fas fa-clock',
              label: '13 hours 25 minutes',
            },
            {
              iconClass: 'fas fa-user',
              label: 'Intermediate',
            },
          ],
          companies : [
            { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
            { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
            { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
            { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
            { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
          ]
      },
    javascript: {
          title: 'JavaScript',
          description: 'This course covers the fundamental skills needed to program interactive web pages using JavaScript.',
          icon: 'FaJsSquare',
          image: javascriptImage,
          buttonText: 'Enroll Now',
          hours: '15',
          link: '/courseDetails?courseName=javascript',
          showCourse: true,
          technologies: ['javascript'],
          syllabus: [
            { title: 'Introduction to JavaScript', hours: 0, minutes: 12 },
            { title: 'JavaScript Syntax and Basics', hours: 0, minutes: 18 },
            { title: 'Variables, Constants, and Data Types', hours: 0, minutes: 15 },
            { title: 'Operators and Expressions', hours: 0, minutes: 12 },
            { title: 'Control Flow (If-Else, Switch)', hours: 0, minutes: 18 },
            { title: 'Loops (For, While, Do-While)', hours: 0, minutes: 18 },
            { title: 'Functions and Function Expressions', hours: 0, minutes: 24 },
            { title: 'Arrow Functions', hours: 0, minutes: 12 },
            { title: 'JavaScript Scope (Global, Local, Block)', hours: 0, minutes: 15 },
            { title: 'Closures and Lexical Environment', hours: 0, minutes: 18 },
            { title: 'JavaScript Objects and Object Literals', hours: 0, minutes: 24 },
            { title: 'Arrays and Array Methods', hours: 0, minutes: 21 },
            { title: 'Strings and String Manipulation', hours: 0, minutes: 15 },
            { title: 'JavaScript Dates and Times', hours: 0, minutes: 12 },
            { title: 'Numbers and Math Object', hours: 0, minutes: 12 },
            { title: 'JavaScript DOM Manipulation', hours: 0, minutes: 26 },
            { title: 'DOM Events and Event Handling', hours: 0, minutes: 21 },
            { title: 'Form Validation with JavaScript', hours: 0, minutes: 26 },
            { title: 'JavaScript Timing Events (setTimeout, setInterval)', hours: 0, minutes: 12 },
            { title: 'JavaScript Error Handling (Try, Catch, Finally)', hours: 0, minutes: 18 },
            { title: 'Introduction to JavaScript ES6', hours: 0, minutes: 15 },
            { title: 'JavaScript Classes and Object-Oriented Programming', hours: 0, minutes: 24 },
            { title: 'JavaScript Modules (Import, Export)', hours: 0, minutes: 18 },
            { title: 'Asynchronous JavaScript (Callbacks, Promises)', hours: 0, minutes: 26 },
            { title: 'Async/Await in JavaScript', hours: 0, minutes: 21 },
            { title: 'JavaScript Fetch API and AJAX', hours: 0, minutes: 26 },
            { title: 'Working with JSON in JavaScript', hours: 0, minutes: 15 },
            { title: 'JavaScript Regular Expressions', hours: 0, minutes: 18 },
            { title: 'JavaScript Event Bubbling and Capturing', hours: 0, minutes: 18 },
            { title: 'Browser Events and User Interaction', hours: 0, minutes: 12 },
            { title: 'JavaScript LocalStorage and SessionStorage', hours: 0, minutes: 18 },
            { title: 'Introduction to JavaScript Frameworks and Libraries', hours: 0, minutes: 12 },
            { title: 'Debugging JavaScript with Developer Tools', hours: 0, minutes: 18 },
            { title: 'JavaScript and Web APIs', hours: 0, minutes: 18 },
            { title: 'Error Handling and Debugging', hours: 0, minutes: 15 },
            { title: 'Working with APIs in JavaScript', hours: 0, minutes: 29 },
            { title: 'JavaScript Design Patterns', hours: 0, minutes: 21 },
            { title: 'JavaScript Best Practices', hours: 0, minutes: 15 },
            { title: 'JavaScript and Performance Optimization', hours: 0, minutes: 21 },
            { title: 'Testing and Debugging JavaScript Code', hours: 0, minutes: 24 },
            { title: 'Introduction to Node.js', hours: 0, minutes: 35 },
            { title: 'JavaScript and Security', hours: 0, minutes: 18 },
            { title: 'Building Dynamic Web Pages with JavaScript', hours: 0, minutes: 35 },
            { title: 'JavaScript and Progressive Web Apps', hours: 0, minutes: 35 },
            { title: 'Final Project: Building a Dynamic Application with JavaScript', hours: 1, minutes: 10 }
          ],
          skills: [
            'Mastering JavaScript syntax and basic programming concepts',
            'Understanding variables, constants, and data types',
            'Implementing control flow and loops for logic building',
            'Defining and using functions, including arrow functions',
            'Manipulating the DOM and handling events effectively',
            'Utilizing asynchronous programming with callbacks, promises, and async/await'
          ],
          courseInfo: [
            {
              iconClass: 'fas fa-laptop-code',
              label: '6+ skills',
            },
            {
              iconClass: 'fas fa-clock',
              label: 'Approximately 15 hours',
            },
            {
              iconClass: 'fas fa-user',
              label: 'Intermediate',
            },
          ],
          companies : [
            { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
            { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
            { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
            { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
            { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
          ]
        },
    bootstrap: {
        title: 'Bootstrap',
        description: 'This course covers the fundamental skills needed to create responsive web designs using Bootstrap.',
        icon: 'FaBootstrap',
        image: '',
        buttonText: 'Enroll Now',
        hours: '',
        link: '',
        showCourse: false,
        syllabus: [
          { title: 'Introduction to Bootstrap', hours: 0, minutes: 5 },
          { title: 'Setting Up Bootstrap', hours: 0, minutes: 10 },
          { title: 'Bootstrap Grid System', hours: 0, minutes: 15 },
          { title: 'Bootstrap Containers (Fluid, Fixed)', hours: 0, minutes: 10 },
          { title: 'Responsive Design with Bootstrap', hours: 0, minutes: 15 },
          { title: 'Typography in Bootstrap', hours: 0, minutes: 10 },
          { title: 'Bootstrap Colors and Backgrounds', hours: 0, minutes: 10 },
          { title: 'Bootstrap Buttons and Button Groups', hours: 0, minutes: 15 },
          { title: 'Bootstrap Icons', hours: 0, minutes: 10 },
          { title: 'Bootstrap Tables', hours: 0, minutes: 15 },
          { title: 'Bootstrap Forms', hours: 0, minutes: 15 },
          { title: 'Input Groups and Form Controls', hours: 0, minutes: 10 },
          { title: 'Form Validation with Bootstrap', hours: 0, minutes: 10 },
          { title: 'Bootstrap Alerts', hours: 0, minutes: 10 },
          { title: 'Bootstrap Badges and Labels', hours: 0, minutes: 10 },
          { title: 'Bootstrap Breadcrumbs', hours: 0, minutes: 5 },
          { title: 'Bootstrap Cards', hours: 0, minutes: 15 },
          { title: 'Bootstrap Carousel', hours: 0, minutes: 10 },
          { title: 'Bootstrap Collapse', hours: 0, minutes: 10 },
          { title: 'Bootstrap Dropdowns', hours: 0, minutes: 10 },
          { title: 'Bootstrap List Groups', hours: 0, minutes: 10 },
          { title: 'Bootstrap Modals', hours: 0, minutes: 15 },
          { title: 'Bootstrap Navs and Navbar', hours: 0, minutes: 15 },
          { title: 'Bootstrap Pagination', hours: 0, minutes: 5 },
          { title: 'Bootstrap Popovers and Tooltips', hours: 0, minutes: 15 },
          { title: 'Bootstrap Progress Bars', hours: 0, minutes: 10 },
          { title: 'Bootstrap Spinners', hours: 0, minutes: 5 },
          { title: 'Bootstrap Toasts', hours: 0, minutes: 10 },
          { title: 'Customizing Bootstrap Components', hours: 0, minutes: 15 },
          { title: 'Bootstrap Utility Classes', hours: 0, minutes: 10 },
          { title: 'Using Bootstrap Icons', hours: 0, minutes: 5 },
          { title: 'Bootstrap Layouts (Columns, Flexbox)', hours: 0, minutes: 15 },
          { title: 'Responsive Media Queries with Bootstrap', hours: 0, minutes: 10 },
          { title: 'Bootstrap and JavaScript Components', hours: 0, minutes: 15 },
          { title: 'Creating Responsive Navigation Bars', hours: 0, minutes: 15 },
          { title: 'Building Layouts with Bootstrap Grid System', hours: 0, minutes: 15 },
          { title: 'Customizing Themes in Bootstrap', hours: 0, minutes: 10 },
          { title: 'Advanced Bootstrap Customization with Sass', hours: 0, minutes: 15 },
          { title: 'Bootstrap Best Practices and Optimization', hours: 0, minutes: 10 },
          { title: 'Final Project: Building a Responsive Website with Bootstrap', hours: 1, minutes: 0 },
      ],
        skills: [
            'Mastering Bootstrap’s Grid System for responsive design',
            'Creating and customizing forms using Bootstrap',
            'Utilizing Bootstrap components like modals, alerts, and carousels',
            'Implementing responsive design techniques and utility classes',
            'Using Bootstrap with JavaScript for dynamic behavior',
            'Optimizing web pages for performance using Bootstrap best practices',
        ],
        courseInfo: [
            {
            iconClass: 'fas fa-laptop-code',
            label: '6 Skills',
            },
            {
            iconClass: 'fas fa-clock',
            label: 'Approximately 13 hours',
            },
            {
            iconClass: 'fas fa-user',
            label: 'Beginner to Intermediate',
            },
        ],
        companies : []
        },
    react: {
        title: 'React JS',
        description: 'This course covers the fundamental and advanced skills needed to build interactive web applications using ReactJS.',
        icon: 'FaReact',
        image: reactjsImage,
        buttonText: 'Enroll Now',
        hours: '18',
        link: '/courseDetails?courseName=react',
        syllabus: [
          { title: 'Introduction to ReactJS', hours: 0, minutes: 11 },
          { title: 'Setting Up the React Development Environment', hours: 0, minutes: 14 },
          { title: 'Introduction to JSX', hours: 0, minutes: 11 },
          { title: 'Functional Components in React', hours: 0, minutes: 17 },
          { title: 'Props in Functional Components', hours: 0, minutes: 14 },
          { title: 'State Management with useState Hook', hours: 0, minutes: 23 },
          { title: 'Handling Events in Functional Components', hours: 0, minutes: 17 },
          { title: 'Conditional Rendering in Functional Components', hours: 0, minutes: 23 },
          { title: 'Rendering Lists and Keys', hours: 0, minutes: 20 },
          { title: 'Working with Forms in React', hours: 0, minutes: 23 },
          { title: 'Controlled vs Uncontrolled Components', hours: 0, minutes: 17 },
          { title: 'React Router for Navigation', hours: 0, minutes: 34 },
          { title: 'Introduction to React Hooks', hours: 0, minutes: 11 },
          { title: 'Using useEffect for Side Effects', hours: 0, minutes: 40 },
          { title: 'Context API for State Management', hours: 0, minutes: 29 },
          { title: 'Creating Custom Hooks', hours: 0, minutes: 23 },
          { title: 'JavaScript ES6 Features in React', hours: 0, minutes: 20 },
          { title: 'Memoization with React.memo', hours: 0, minutes: 14 },
          { title: 'Performance Optimization with useMemo and useCallback', hours: 0, minutes: 26 },
          { title: 'React Fragment and Short Syntax', hours: 0, minutes: 9 },
          { title: 'Ref Management with useRef', hours: 0, minutes: 17 },
          { title: 'Error Boundaries in Functional Components', hours: 0, minutes: 20 },
          { title: 'Lazy Loading with React.lazy and Suspense', hours: 0, minutes: 23 },
          { title: 'Data Fetching with useEffect and APIs', hours: 0, minutes: 20 },
          { title: 'Working with REST APIs in React', hours: 0, minutes: 34 },
          { title: 'Integrating GraphQL in React Applications', hours: 0, minutes: 29 },
          { title: 'Introduction to Redux with Functional Components', hours: 0, minutes: 34 },
          { title: 'Using Redux with Hooks (useSelector, useDispatch)', hours: 0, minutes: 29 },
          { title: 'Asynchronous Actions with Redux Thunk', hours: 0, minutes: 23 },
          { title: 'Building Forms with Formik and React Hook Form', hours: 0, minutes: 34 },
          { title: 'Testing Functional Components (Jest, React Testing Library)', hours: 0, minutes: 34 },
          { title: 'React with TypeScript', hours: 0, minutes: 26 },
          { title: 'Handling Forms and Validation', hours: 0, minutes: 20 },
          { title: 'React and WebSocket for Real-Time Applications', hours: 0, minutes: 26 },
          { title: 'Optimizing React Applications for Performance', hours: 0, minutes: 29 },
          { title: 'Deploying React Applications to Production', hours: 0, minutes: 29 },
          { title: 'Static Site Generation (SSG) with Next.js', hours: 0, minutes: 40 },
          { title: 'Progressive Web Apps (PWA) with React', hours: 0, minutes: 26 },
          { title: 'Security Best Practices in React', hours: 0, minutes: 23 },
          { title: 'Using React with Tailwind CSS', hours: 0, minutes: 23 },
          { title: 'Integrating Material-UI with React', hours: 0, minutes: 34 },
          { title: 'Building a Full-Stack Application with React and Node.js', hours: 0, minutes: 34 },
          { title: 'Final Project: Building a Complete React Application', hours: 1, minutes: 8 }
        ],
        technologies: ['react'],
        showCourse: true,
        skills: [
            'Mastering React fundamentals, including JSX and functional components',
            'Managing state and props effectively in React applications',
            'Utilizing React hooks for state management and side effects',
            'Implementing routing and navigation with React Router',
            'Fetching and handling data from APIs asynchronously',
            'Building and testing interactive web applications effectively'
        ],
        courseInfo: [
            {
            iconClass: 'fas fa-laptop-code',
            label: '6+ Skills',
            },
            {
            iconClass: 'fas fa-clock',
            label: 'Approximately 18 hours',
            },
            {
            iconClass: 'fas fa-user',
            label: 'Intermediate',
            },
        ],
        companies : [
          { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' },
          { name: 'tcs', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/99/TATA_Consultancy_Services_Logo_blue.svg' },
          { name: 'infosys', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/95/Infosys_logo.svg' },
          { name: 'facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Facebook_New_Logo_%282015%29.svg' },
          { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
          { name: 'Cognizant', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/43/Cognizant_logo_2022.svg' },
        ],
        feedbackData : [
          { text: "This course helped me land my first job as a React developer!", author: "Dharshini" },
          { text: "The hands-on projects were the best part. I learned a lot!", author: "Arjun" },
          { text: "Great support from instructors. Highly recommend!", author: "Archana" }
        ]
        },
    angularjs: {
      title: 'Angular JS',
      description: 'This course covers the fundamental and advanced skills needed to develop applications using AngularJS.',
      icon: 'FaAngular', // Consider using a suitable icon for AngularJS
      image: angularjsImage,
      buttonText: 'Enroll Now',
      hours: '27',
      link: '/courseDetails?courseName=angularjs',
      syllabus: [
        { title: 'Introduction to AngularJS', hours: 0, minutes: 20 },
        { title: 'Setting Up the AngularJS Environment', hours: 0, minutes: 20 },
        { title: 'Understanding MVC Architecture in AngularJS', hours: 0, minutes: 30 },
        { title: 'Creating Your First AngularJS App', hours: 0, minutes: 30 },
        { title: 'Modules in AngularJS', hours: 0, minutes: 20 },
        { title: 'Controllers in AngularJS', hours: 0, minutes: 30 },
        { title: 'Scope in AngularJS', hours: 0, minutes: 25 },
        { title: 'Directives in AngularJS', hours: 0, minutes: 35 },
        { title: 'Expressions in AngularJS', hours: 0, minutes: 25 },
        { title: 'Two-Way Data Binding', hours: 0, minutes: 40 },
        { title: 'Working with Filters in AngularJS', hours: 0, minutes: 20 },
        { title: 'Forms and Form Validation', hours: 0, minutes: 40 },
        { title: 'Services in AngularJS', hours: 0, minutes: 30 },
        { title: 'Dependency Injection in AngularJS', hours: 0, minutes: 25 },
        { title: 'Factories and Providers', hours: 0, minutes: 30 },
        { title: 'Routing in AngularJS with ngRoute', hours: 0, minutes: 35 },
        { title: 'Single Page Applications (SPA) in AngularJS', hours: 0, minutes: 30 },
        { title: 'Custom Directives in AngularJS', hours: 0, minutes: 30 },
        { title: '$http Service for API Communication', hours: 0, minutes: 35 },
        { title: 'Handling AJAX Requests in AngularJS', hours: 0, minutes: 30 },
        { title: 'Error Handling in AngularJS', hours: 0, minutes: 25 },
        { title: 'Animations with ngAnimate', hours: 0, minutes: 30 },
        { title: 'Working with Promises in AngularJS', hours: 0, minutes: 35 },
        { title: 'Creating and Using Custom Services', hours: 0, minutes: 40 },
        { title: 'RESTful API Integration in AngularJS', hours: 0, minutes: 35 },
        { title: 'Using $resource for RESTful Services', hours: 0, minutes: 35 },
        { title: 'Interceptors in AngularJS', hours: 0, minutes: 30 },
        { title: 'Authentication and Authorization in AngularJS', hours: 0, minutes: 30 },
        { title: 'Working with AngularJS Forms and User Input', hours: 0, minutes: 35 },
        { title: 'Client-Side Validation in AngularJS', hours: 0, minutes: 35 },
        { title: 'AngularJS and Web Components', hours: 0, minutes: 35 },
        { title: 'Performance Optimization in AngularJS Applications', hours: 0, minutes: 35 },
        { title: 'Unit Testing AngularJS Applications with Jasmine', hours: 0, minutes: 35 },
        { title: 'E2E Testing with Protractor', hours: 0, minutes: 35 },
        { title: 'Building Modular AngularJS Applications', hours: 0, minutes: 40 },
        { title: 'Managing Application State in AngularJS', hours: 0, minutes: 35 },
        { title: 'Real-Time Applications with AngularJS and WebSockets', hours: 0, minutes: 40 },
        { title: 'AngularJS with Firebase', hours: 0, minutes: 35 },
        { title: 'Security Considerations in AngularJS', hours: 0, minutes: 35 },
        { title: 'SEO Optimization in AngularJS', hours: 0, minutes: 30 },
        { title: 'Version Control with AngularJS', hours: 0, minutes: 30 },
        { title: 'Best Practices for AngularJS Development', hours: 0, minutes: 35 },
        { title: 'Using AngularJS with Third-Party Libraries', hours: 0, minutes: 30 },
        { title: 'Migrating from AngularJS to Angular (Angular 2+)', hours: 0, minutes: 40 },
        { title: 'Working with AngularJS and Node.js', hours: 0, minutes: 35 },
        { title: 'Deploying AngularJS Applications', hours: 0, minutes: 35 },
        { title: 'Optimizing Performance with Lazy Loading', hours: 0, minutes: 35 },
        { title: 'Responsive Design with AngularJS', hours: 0, minutes: 35 },
        { title: 'Final Project: Building a Complete AngularJS Application', hours: 2, minutes: 0 }
      ],         
      technologies: ['angularjs'],
      showCourse: true,
      skills: [
        'Understanding the core concepts of AngularJS and MVC architecture',
        'Building dynamic web applications using AngularJS',
        'Implementing two-way data binding and scope management',
        'Creating and using services, filters, and directives',
        'Handling forms and validation effectively',
        'Integrating AngularJS applications with RESTful APIs',
        'Testing AngularJS applications using Jasmine and Protractor',
        'Optimizing performance and ensuring security best practices',
      ],
      courseInfo: [
        {
          iconClass: 'fas fa-angular',
          label: '8+ Skills',
        },
        {
          iconClass: 'fas fa-clock',
          label: 'Approximately 27 hours',
        },
        {
          iconClass: 'fas fa-user',
          label: 'Intermediate to Advanced',
        },
      ],
      companies : [
        { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
        { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
        { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
      ]
    },
    nodejs: {
      title: 'Node Js',
      description: 'This course covers the fundamental and advanced skills needed to develop applications using Node.js.',
      icon: 'FaNodeJs', // Consider using a suitable icon for Node.js
      image: nodejsImage,
      buttonText: 'Enroll Now',
      hours: '17',
      link: '/courseDetails?courseName=nodejs',
      syllabus: [
        { title: 'Introduction to Node.js', hours: 0, minutes: 10 },
        { title: 'Node.js Architecture and Event-Driven Model', hours: 0, minutes: 15 },
        { title: 'Setting Up the Node.js Environment', hours: 0, minutes: 12 },
        { title: 'Understanding the Node.js Event Loop', hours: 0, minutes: 20 },
        { title: 'Node.js Modules and require System', hours: 0, minutes: 15 },
        { title: 'Core Modules in Node.js', hours: 0, minutes: 22 },
        { title: 'Creating and Running a Basic Node.js Server', hours: 0, minutes: 20 },
        { title: 'Working with the File System Module (fs)', hours: 0, minutes: 18 },
        { title: 'Handling Paths and URLs in Node.js', hours: 0, minutes: 12 },
        { title: 'Asynchronous Programming in Node.js', hours: 0, minutes: 22 },
        { title: 'Callbacks in Node.js', hours: 0, minutes: 15 },
        { title: 'Promises and async/await in Node.js', hours: 0, minutes: 20 },
        { title: 'Handling HTTP Requests and Responses', hours: 0, minutes: 20 },
        { title: 'Streams and Buffers in Node.js', hours: 0, minutes: 18 },
        { title: 'Working with the EventEmitter', hours: 0, minutes: 15 },
        { title: 'Working with Node Package Manager (npm)', hours: 0, minutes: 15 },
        { title: 'Managing Dependencies with npm', hours: 0, minutes: 12 },
        { title: 'Introduction to Express.js', hours: 0, minutes: 17 },
        { title: 'Building RESTful APIs with Express.js', hours: 0, minutes: 30 },
        { title: 'Middleware in Express.js', hours: 0, minutes: 22 },
        { title: 'Routing in Express.js', hours: 0, minutes: 20 },
        { title: 'Handling Forms and Input Data with Body Parser', hours: 0, minutes: 18 },
        { title: 'Template Engines (EJS, Pug, Handlebars)', hours: 0, minutes: 20 },
        { title: 'Working with Databases (MongoDB, MySQL) in Node.js', hours: 0, minutes: 30 },
        { title: 'MongoDB Integration with Mongoose', hours: 0, minutes: 25 },
        { title: 'Relational Databases and Sequelize ORM', hours: 0, minutes: 22 },
        { title: 'User Authentication with JWT (JSON Web Tokens)', hours: 0, minutes: 25 },
        { title: 'Session Management and Cookies', hours: 0, minutes: 20 },
        { title: 'OAuth2 and Social Login Integration', hours: 0, minutes: 20 },
        { title: 'File Uploading and Downloading in Node.js', hours: 0, minutes: 20 },
        { title: 'Error Handling in Node.js Applications', hours: 0, minutes: 20 },
        { title: 'Logging in Node.js (Winston, Morgan)', hours: 0, minutes: 18 },
        { title: 'Real-Time Communication with WebSockets in Node.js', hours: 0, minutes: 22 },
        { title: 'Introduction to Socket.io for Real-Time Apps', hours: 0, minutes: 22 },
        { title: 'Using Node.js with GraphQL', hours: 0, minutes: 25 },
        { title: 'Working with External APIs', hours: 0, minutes: 22 },
        { title: 'Rate Limiting and Throttling in Node.js', hours: 0, minutes: 15 },
        { title: 'Node.js Security Best Practices', hours: 0, minutes: 25 },
        { title: 'Building a Secure API with Node.js', hours: 0, minutes: 25 },
        { title: 'Testing Node.js Applications (Mocha, Chai, Jest)', hours: 0, minutes: 22 },
        { title: 'Performance Optimization in Node.js', hours: 0, minutes: 25 },
        { title: 'Introduction to Microservices with Node.js', hours: 0, minutes: 20 },
        { title: 'Building Microservices with Express and Node.js', hours: 0, minutes: 25 },
        { title: 'Working with Message Queues in Node.js (RabbitMQ, Kafka)', hours: 0, minutes: 25 },
        { title: 'Node.js and Docker Integration', hours: 0, minutes: 22 },
        { title: 'Deploying Node.js Applications (Heroku, AWS, etc.)', hours: 0, minutes: 25 },
        { title: 'Clustering and Load Balancing in Node.js', hours: 0, minutes: 20 },
        { title: 'Scaling Node.js Applications', hours: 0, minutes: 25 },
        { title: 'Version Control and CI/CD for Node.js', hours: 0, minutes: 22 },
        { title: 'Final Project: Building and Deploying a Full-Featured Node.js Application', hours: 1, minutes: 0 }
      ],
      technologies: ['nodejs'],
      showCourse: true,
      skills: [
        'Understanding Node.js architecture and core concepts',
        'Building web servers and RESTful APIs using Express.js',
        'Implementing asynchronous programming patterns',
        'Handling authentication and session management',
        'Integrating databases with Node.js applications',
        'Testing and debugging Node.js applications effectively',
        'Deploying and scaling Node.js applications in production',
        'Utilizing real-time communication features with WebSockets',
      ],
      courseInfo: [
        {
          iconClass: 'fas fa-node-js',
          label: '8+ skills',
        },
        {
          iconClass: 'fas fa-clock',
          label: 'Approximately 27 hours',
        },
        {
          iconClass: 'fas fa-user',
          label: 'Intermediate to Advanced',
        },
      ],
      companies : [
        { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
        { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
        { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
      ]
    },
    express: {
      title: 'Express Js',
      description: 'This course covers the fundamental and advanced skills needed to develop web applications using Express.js.',
      icon: 'FaNodeJs', // Consider using a suitable icon for Express.js
      image: expressImage,
      buttonText: 'Enroll Now',
      hours: '16',
      link: '/courseDetails?courseName=express',
      syllabus: [
        { title: 'Introduction to Express.js', hours: 0, minutes: 10 },
        { title: 'Setting Up the Express.js Environment', hours: 0, minutes: 15 },
        { title: 'Understanding Express.js Architecture', hours: 0, minutes: 20 },
        { title: 'Creating a Basic Server with Express.js', hours: 0, minutes: 18 },
        { title: 'Routing in Express.js', hours: 0, minutes: 20 },
        { title: 'Handling GET, POST, PUT, DELETE Requests', hours: 0, minutes: 20 },
        { title: 'Query Parameters and Route Parameters', hours: 0, minutes: 15 },
        { title: 'Middleware in Express.js', hours: 0, minutes: 25 },
        { title: 'Using Third-Party Middleware', hours: 0, minutes: 18 },
        { title: 'Creating Custom Middleware', hours: 0, minutes: 18 },
        { title: 'Serving Static Files in Express.js', hours: 0, minutes: 15 },
        { title: 'Template Engines (EJS, Pug, Handlebars) with Express.js', hours: 0, minutes: 20 },
        { title: 'Form Handling and Parsing Data with Body-Parser', hours: 0, minutes: 18 },
        { title: 'Handling File Uploads in Express.js', hours: 0, minutes: 20 },
        { title: 'Error Handling in Express.js', hours: 0, minutes: 25 },
        { title: 'Modularizing Routes and Controllers', hours: 0, minutes: 20 },
        { title: 'Express.js and RESTful API Development', hours: 0, minutes: 30 },
        { title: 'Working with Databases in Express.js (MongoDB, MySQL)', hours: 0, minutes: 30 },
        { title: 'Authentication in Express.js using JWT', hours: 0, minutes: 25 },
        { title: 'Session Management and Cookies in Express.js', hours: 0, minutes: 20 },
        { title: 'OAuth2 and Social Login Integration (Google, Facebook)', hours: 0, minutes: 25 },
        { title: 'Security Best Practices in Express.js', hours: 0, minutes: 20 },
        { title: 'Rate Limiting and Data Validation', hours: 0, minutes: 20 },
        { title: 'Working with CORS in Express.js', hours: 0, minutes: 15 },
        { title: 'Logging in Express.js (Winston, Morgan)', hours: 0, minutes: 18 },
        { title: 'Real-time Communication with Express.js and Socket.io', hours: 0, minutes: 25 },
        { title: 'Building a WebSocket Server with Express.js', hours: 0, minutes: 20 },
        { title: 'Express.js with GraphQL', hours: 0, minutes: 25 },
        { title: 'Pagination and Filtering in APIs', hours: 0, minutes: 20 },
        { title: 'Versioning APIs in Express.js', hours: 0, minutes: 15 },
        { title: 'Handling File Downloads in Express.js', hours: 0, minutes: 18 },
        { title: 'Unit Testing and Integration Testing in Express.js', hours: 0, minutes: 25 },
        { title: 'Using Mocha, Chai, and Supertest for Testing', hours: 0, minutes: 25 },
        { title: 'API Documentation with Swagger in Express.js', hours: 0, minutes: 25 },
        { title: 'Performance Optimization Techniques', hours: 0, minutes: 25 },
        { title: 'Error Monitoring and Logging in Production', hours: 0, minutes: 20 },
        { title: 'Working with Node.js Streams in Express.js', hours: 0, minutes: 18 },
        { title: 'Deploying Express.js Applications', hours: 0, minutes: 25 },
        { title: 'Scaling Express.js Applications with Clustering', hours: 0, minutes: 25 },
        { title: 'Express.js and Docker Integration', hours: 0, minutes: 25 },
        { title: 'Using Express.js with Cloud Platforms (AWS, Azure)', hours: 0, minutes: 25 },
        { title: 'Continuous Integration/Continuous Deployment (CI/CD) for Express.js', hours: 0, minutes: 20 },
        { title: 'Securing Express.js Applications with Helmet', hours: 0, minutes: 20 },
        { title: 'Final Project: Building a Full-Stack Web Application with Express.js', hours: 1, minutes: 0 }
      ],
      technologies: ['express'],
      showCourse: true,
      skills: [
        'Understanding Express.js architecture and core concepts',
        'Building RESTful APIs and web applications with Express.js',
        'Implementing middleware and routing effectively',
        'Handling authentication and user sessions securely',
        'Integrating databases and managing data efficiently',
        'Testing and debugging Express.js applications',
        'Deploying and scaling Express.js applications in production',
        'Utilizing real-time communication features with Socket.io',
      ],
      courseInfo: [
        {
          iconClass: 'fas fa-node-js',
          label: '8+ Skills',
        },
        {
          iconClass: 'fas fa-clock',
          label: 'Approximately 16 hours',
        },
        {
          iconClass: 'fas fa-user',
          label: 'Intermediate to Advanced',
        },
      ],
      companies : [
        { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
        { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
        { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
      ]
    },
    mongodb: {
      title: 'MongoDB',
      description: 'This course covers the fundamental and advanced skills needed to work with MongoDB and NoSQL databases.',
      icon: 'FaDatabase', // Consider using a suitable icon for MongoDB
      image: '',
      buttonText: 'Enroll Now',
      hours: '',
      link: '',
      syllabus: [
        { title: 'Introduction to MongoDB', hours: 0, minutes: 10 },
        { title: 'MongoDB Architecture and NoSQL Concepts', hours: 0, minutes: 15 },
        { title: 'Setting Up MongoDB Environment', hours: 0, minutes: 15 },
        { title: 'Understanding MongoDB Collections and Documents', hours: 0, minutes: 20 },
        { title: 'CRUD Operations in MongoDB', hours: 0, minutes: 25 },
        { title: 'Data Modeling in MongoDB', hours: 0, minutes: 30 },
        { title: 'MongoDB Indexes and Their Importance', hours: 0, minutes: 25 },
        { title: 'Aggregation Framework in MongoDB', hours: 0, minutes: 30 },
        { title: 'MongoDB and Mongoose in Node.js', hours: 0, minutes: 30 },
        { title: 'Schema Design and Validation with Mongoose', hours: 0, minutes: 30 },
        { title: 'MongoDB Replication and Sharding Concepts', hours: 0, minutes: 30 },
        { title: 'MongoDB Transactions', hours: 0, minutes: 25 },
        { title: 'Backup and Restore in MongoDB', hours: 0, minutes: 20 },
        { title: 'MongoDB Security Best Practices', hours: 0, minutes: 25 },
        { title: 'Authentication and Authorization in MongoDB', hours: 0, minutes: 25 },
        { title: 'Working with MongoDB Aggregation Pipelines', hours: 0, minutes: 30 },
        { title: 'MongoDB Atlas (Cloud Database as a Service)', hours: 0, minutes: 20 },
        { title: 'Deploying MongoDB in a Production Environment', hours: 0, minutes: 30 },
        { title: 'Monitoring and Analytics in MongoDB', hours: 0, minutes: 20 },
        { title: 'MongoDB and Docker Integration', hours: 0, minutes: 25 },
        { title: 'Scaling MongoDB Databases', hours: 0, minutes: 25 },
        { title: 'MongoDB and Microservices', hours: 0, minutes: 25 },
        { title: 'Final Project: Building a Scalable Application with MongoDB', hours: 1, minutes: 0 }
      ],
      technologies: ['mongodb'],
      showCourse: false,
      skills: [
        'Understanding MongoDB architecture and NoSQL concepts',
        'Performing CRUD operations and data modeling in MongoDB',
        'Working with Mongoose for MongoDB in Node.js applications',
        'Implementing indexing and aggregation for performance optimization',
        'Ensuring data security and managing user access',
        'Integrating MongoDB with various frameworks and technologies',
        'Deploying and managing MongoDB in production environments',
        'Utilizing MongoDB for real-time applications and analytics',
      ],
      courseInfo: [
        {
          iconClass: 'fas fa-database',
          label: '8 Skills',
        },
        {
          iconClass: 'fas fa-clock',
          label: 'Comprehensive Coverage',
        },
        {
          iconClass: 'fas fa-user',
          label: 'Intermediate to Advanced',
        },
      ],
      companies : [
        { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
        { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
        { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
      ]
    },
    python: {
      title: 'Python',
      description: 'This course covers the fundamental and advanced skills needed to develop applications using Python.',
      icon: 'FaPython', // Consider using a suitable icon for Python
      image: pythonImage,
      buttonText: 'Enroll Now',
      hours: '34',
      link: '/courseDetails?courseName=python',
      syllabus: [
        { title: 'Introduction to Python', hours: 0, minutes: 20 },
        { title: 'Setting Up Python Development Environment', hours: 0, minutes: 30 },
        { title: 'Python Syntax and Structure', hours: 0, minutes: 30 },
        { title: 'Variables and Data Types', hours: 0, minutes: 40 },
        { title: 'Operators in Python', hours: 0, minutes: 30 },
        { title: 'Control Flow (If-Else, Switch)', hours: 0, minutes: 40 },
        { title: 'Loops (For, While)', hours: 0, minutes: 40 },
        { title: 'Functions in Python', hours: 0, minutes: 45 },
        { title: 'Lambda Functions and Anonymous Functions', hours: 0, minutes: 30 },
        { title: 'List, Tuples, and Sets', hours: 0, minutes: 40 },
        { title: 'Dictionaries and Dictionary Methods', hours: 0, minutes: 40 },
        { title: 'File Handling (Reading, Writing)', hours: 0, minutes: 40 },
        { title: 'Error Handling (Try, Except, Finally)', hours: 0, minutes: 40 },
        { title: 'Object-Oriented Programming in Python', hours: 0, minutes: 50 },
        { title: 'Classes and Objects', hours: 0, minutes: 50 },
        { title: 'Inheritance, Polymorphism, and Encapsulation', hours: 0, minutes: 50 },
        { title: 'Decorators and Generators', hours: 0, minutes: 40 },
        { title: 'Comprehensions (List, Dict, Set, Generator)', hours: 0, minutes: 40 },
        { title: 'Working with Strings in Python', hours: 0, minutes: 30 },
        { title: 'Working with Dates and Time', hours: 0, minutes: 30 },
        { title: 'Python Modules and Packages', hours: 0, minutes: 40 },
        { title: 'Using pip to Manage Dependencies', hours: 0, minutes: 25 },
        { title: 'Working with Regular Expressions', hours: 0, minutes: 35 },
        { title: 'Understanding Scope and Namespaces', hours: 0, minutes: 30 },
        { title: 'Python\'s Built-in Functions', hours: 0, minutes: 35 },
        { title: 'Advanced Data Structures (Heap, Queue, Stack)', hours: 0, minutes: 45 },
        { title: 'Introduction to Python Libraries (NumPy, Pandas, Matplotlib)', hours: 0, minutes: 40 },
        { title: 'Data Manipulation with Pandas', hours: 0, minutes: 45 },
        { title: 'Data Visualization with Matplotlib', hours: 0, minutes: 40 },
        { title: 'Working with APIs (Requests, JSON)', hours: 0, minutes: 40 },
        { title: 'Web Scraping with BeautifulSoup and Scrapy', hours: 0, minutes: 50 },
        { title: 'Introduction to SQL with Python', hours: 0, minutes: 40 },
        { title: 'Working with Databases (SQLite, PostgreSQL)', hours: 0, minutes: 45 },
        { title: 'Introduction to Networking in Python (Sockets, FTP)', hours: 0, minutes: 40 },
        { title: 'Multithreading and Multiprocessing', hours: 0, minutes: 45 },
        { title: 'Asynchronous Programming in Python (Asyncio)', hours: 0, minutes: 40 },
        { title: 'Unit Testing and Debugging in Python', hours: 0, minutes: 40 },
        { title: 'Introduction to Django Framework', hours: 0, minutes: 45 },
        { title: 'Introduction to Flask Framework', hours: 0, minutes: 40 },
        { title: 'Web Development with Flask', hours: 0, minutes: 50 },
        { title: 'RESTful APIs with Flask and Django', hours: 0, minutes: 50 },
        { title: 'Working with GraphQL in Python', hours: 0, minutes: 40 },
        { title: 'Introduction to Machine Learning with Python', hours: 0, minutes: 50 },
        { title: 'Working with TensorFlow and Keras', hours: 0, minutes: 50 },
        { title: 'Introduction to Data Science with Python', hours: 0, minutes: 50 },
        { title: 'Automation with Python (Selenium, PyAutoGUI)', hours: 0, minutes: 45 },
        { title: 'Working with Cloud Services (AWS, GCP) in Python', hours: 0, minutes: 40 },
        { title: 'Deploying Python Applications', hours: 0, minutes: 45 },
        { title: 'Version Control and CI/CD with Python', hours: 0, minutes: 40 },
        { title: 'Final Project: Building a Complete Python Application', hours: 2, minutes: 0 },
      ],
      
      technologies: ['python'],
      showCourse: true,
      skills: [
        'Understanding Python syntax and core programming concepts',
        'Implementing object-oriented programming principles',
        'Working with data manipulation and visualization libraries',
        'Handling exceptions and errors effectively',
        'Building web applications using Flask and Django',
        'Integrating databases with Python applications',
        'Testing and debugging Python applications',
        'Deploying Python applications in various environments',
      ],
      courseInfo: [
        {
          iconClass: 'fas fa-laptop-code',
          label: '8 Skills',
        },
        {
          iconClass: 'fas fa-clock',
          label: 'Approximately 34 hours',
        },
        {
          iconClass: 'fas fa-user',
          label: 'Intermediate to Advanced',
        },
      ],
      companies : [
        { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
        { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
        { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
      ]
    },
    java: {
      title: 'Java',
      description: 'This course covers the fundamental and advanced skills needed to develop applications using Java.',
      icon: 'FaJava', // Consider using a suitable icon for Java
      image: javaImage,
      buttonText: 'Enroll Now',
      hours: '35',
      link: '/courseDetails?courseName=java',
      syllabus: [
        { title: 'Introduction to Java', hours: 0, minutes: 20 },
        { title: 'Setting Up Java Development Environment', hours: 0, minutes: 30 },
        { title: 'Java Syntax and Structure', hours: 0, minutes: 40 },
        { title: 'Variables and Data Types in Java', hours: 0, minutes: 40 },
        { title: 'Operators in Java', hours: 0, minutes: 30 },
        { title: 'Control Flow (If-Else, Switch)', hours: 0, minutes: 40 },
        { title: 'Loops (For, While, Do-While)', hours: 0, minutes: 40 },
        { title: 'Methods in Java', hours: 0, minutes: 45 },
        { title: 'Object-Oriented Programming Concepts', hours: 0, minutes: 50 },
        { title: 'Classes and Objects in Java', hours: 0, minutes: 50 },
        { title: 'Constructors in Java', hours: 0, minutes: 35 },
        { title: 'Inheritance in Java', hours: 0, minutes: 45 },
        { title: 'Polymorphism (Method Overloading and Overriding)', hours: 0, minutes: 50 },
        { title: 'Abstraction and Interfaces', hours: 0, minutes: 45 },
        { title: 'Encapsulation in Java', hours: 0, minutes: 40 },
        { title: 'Packages in Java', hours: 0, minutes: 30 },
        { title: 'Access Modifiers in Java', hours: 0, minutes: 30 },
        { title: 'Static Keyword in Java', hours: 0, minutes: 25 },
        { title: 'Final Keyword in Java', hours: 0, minutes: 25 },
        { title: 'Exception Handling in Java (Try, Catch, Finally)', hours: 0, minutes: 45 },
        { title: 'Working with Strings in Java', hours: 0, minutes: 30 },
        { title: 'Arrays and ArrayLists in Java', hours: 0, minutes: 45 },
        { title: 'Collections Framework (List, Set, Map)', hours: 0, minutes: 50 },
        { title: 'Generics in Java', hours: 0, minutes: 45 },
        { title: 'File Handling in Java (Input/Output)', hours: 0, minutes: 40 },
        { title: 'Multithreading and Concurrency in Java', hours: 0, minutes: 50 },
        { title: 'Java Memory Management (Garbage Collection)', hours: 0, minutes: 40 },
        { title: 'Java Inner Classes', hours: 0, minutes: 35 },
        { title: 'Lambda Expressions and Functional Programming', hours: 0, minutes: 45 },
        { title: 'Streams API in Java', hours: 0, minutes: 50 },
        { title: 'Introduction to Java 8 Features', hours: 0, minutes: 45 },
        { title: 'Java Input/Output (I/O) and NIO', hours: 0, minutes: 40 },
        { title: 'Java Networking (Sockets, HTTP)', hours: 0, minutes: 40 },
        { title: 'Working with Databases in Java (JDBC)', hours: 0, minutes: 45 },
        { title: 'Introduction to ORM with Hibernate', hours: 0, minutes: 50 },
        { title: 'Introduction to Spring Framework', hours: 0, minutes: 45 },
        { title: 'Spring Boot for Building Java Applications', hours: 0, minutes: 50 },
        { title: 'Unit Testing with JUnit', hours: 0, minutes: 40 },
        { title: 'Maven and Gradle for Dependency Management', hours: 0, minutes: 35 },
        { title: 'Java and RESTful Web Services', hours: 0, minutes: 50 },
        { title: 'Introduction to Microservices with Spring Boot', hours: 0, minutes: 50 },
        { title: 'Java and Security (Encryption, SSL)', hours: 0, minutes: 45 },
        { title: 'Deploying Java Applications', hours: 0, minutes: 45 },
        { title: 'Java Performance Optimization', hours: 0, minutes: 45 },
        { title: 'Working with JavaFX for GUI Development', hours: 0, minutes: 45 },
        { title: 'Java Design Patterns', hours: 0, minutes: 40 },
        { title: 'Version Control with Git in Java', hours: 0, minutes: 30 },
        { title: 'Working with Java in the Cloud (AWS, Azure)', hours: 0, minutes: 40 },
        { title: 'Best Practices and Code Optimization in Java', hours: 0, minutes: 40 },
        { title: 'Final Project: Building a Full-Stack Java Application', hours: 2, minutes: 0 },
      ],
      technologies: ['java'],
      showCourse: true,
      skills: [
        'Understanding Java syntax and core programming concepts',
        'Implementing object-oriented programming principles',
        'Working with Java collections and data structures',
        'Handling exceptions and errors effectively',
        'Building applications using Spring Framework',
        'Integrating databases with Java applications',
        'Testing and debugging Java applications',
        'Deploying Java applications in various environments',
      ],
      courseInfo: [
        {
          iconClass: 'fas fa-laptop-code',
          label: '8 Skills',
        },
        {
          iconClass: 'fas fa-clock',
          label: 'Approximately 35 hours',
        },
        {
          iconClass: 'fas fa-user',
          label: 'Intermediate to Advanced',
        },
      ],
      companies : [
        { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
        { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
        { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
      ]
    },
    typescript: {
      title: 'TypeScript',
      description: 'This course covers the fundamental and advanced skills needed to develop applications using TypeScript.',
      icon: 'FaJsSquare', // Consider using a suitable icon for TypeScript
      image: '',
      buttonText: 'Enroll Now',
      hours: '',
      link: '',
      syllabus: [
        { title: 'Introduction to TypeScript', hours: 0, minutes: 10 },
        { title: 'Setting Up TypeScript Environment', hours: 0, minutes: 10 },
        { title: 'TypeScript vs JavaScript', hours: 0, minutes: 15 },
        { title: 'TypeScript Syntax and Basics', hours: 0, minutes: 20 },
        { title: 'Types in TypeScript (Primitive, Object)', hours: 0, minutes: 20 },
        { title: 'Type Inference in TypeScript', hours: 0, minutes: 15 },
        { title: 'Type Annotations and Explicit Types', hours: 0, minutes: 20 },
        { title: 'Union and Intersection Types', hours: 0, minutes: 15 },
        { title: 'Functions in TypeScript', hours: 0, minutes: 20 },
        { title: 'Optional and Default Parameters', hours: 0, minutes: 15 },
        { title: 'Interfaces in TypeScript', hours: 0, minutes: 20 },
        { title: 'Classes in TypeScript', hours: 0, minutes: 25 },
        { title: 'Generics in TypeScript', hours: 0, minutes: 25 },
        { title: 'Modules in TypeScript', hours: 0, minutes: 15 },
        { title: 'Namespaces in TypeScript', hours: 0, minutes: 15 },
        { title: 'TypeScript with React', hours: 0, minutes: 25 },
        { title: 'TypeScript and Redux', hours: 0, minutes: 20 },
        { title: 'TypeScript and Node.js', hours: 0, minutes: 25 },
        { title: 'Handling Asynchronous Code with TypeScript', hours: 0, minutes: 20 },
        { title: 'Error Handling in TypeScript', hours: 0, minutes: 15 },
        { title: 'TypeScript Best Practices', hours: 0, minutes: 20 },
        { title: 'Linting and Formatting in TypeScript (TSLint, Prettier)', hours: 0, minutes: 10 },
        { title: 'Final Project: Building a Full-Stack Application with TypeScript', hours: 1, minutes: 0 }
    ],
      technologies: ['typescript'],
      showCourse: false,
      skills: [
        'Understanding TypeScript syntax and core programming concepts',
        'Implementing interfaces and classes in TypeScript',
        'Working with types, generics, and enums',
        'Integrating TypeScript with JavaScript libraries and frameworks',
        'Configuring TypeScript projects using tsconfig.json',
        'Building scalable applications using TypeScript',
        'Testing and debugging TypeScript applications effectively',
        'Migrating JavaScript projects to TypeScript seamlessly',
      ],
      courseInfo: [
        {
          iconClass: 'fas fa-laptop-code',
          label: '8 Skills',
        },
        {
          iconClass: 'fas fa-clock',
          label: 'Comprehensive Coverage',
        },
        {
          iconClass: 'fas fa-user',
          label: 'Intermediate to Advanced',
        },
      ],
      companies : [
        { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
        { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
        { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
      ]
    },
    reactNative: {
      title: 'React Native',
      description: 'This course covers the fundamental and advanced skills needed to build cross-platform mobile applications using React Native.',
      icon: 'FaReact', // Consider using a suitable icon for React Native
      image: reactNativeImage,
      buttonText: 'Enroll Now',
      hours: '36',
      link: '/courseDetails?courseName=reactNative',
      syllabus: [
        { title: 'Introduction to React Native', hours: 0, minutes: 20 },
        { title: 'Setting Up the React Native Development Environment', hours: 0, minutes: 30 },
        { title: 'React Native vs React.js', hours: 0, minutes: 30 },
        { title: 'Understanding the Core Concepts of React Native', hours: 0, minutes: 40 },
        { title: 'Creating Your First React Native App', hours: 0, minutes: 45 },
        { title: 'React Native Components Overview', hours: 0, minutes: 40 },
        { title: 'Text, View, and Image Components', hours: 0, minutes: 40 },
        { title: 'Handling User Input with TextInput', hours: 0, minutes: 40 },
        { title: 'Working with Buttons and Touchable Components', hours: 0, minutes: 30 },
        { title: 'React Native Flexbox Layout', hours: 0, minutes: 50 },
        { title: 'Styling in React Native', hours: 0, minutes: 45 },
        { title: 'ScrollView and FlatList Components', hours: 0, minutes: 40 },
        { title: 'SectionList and Advanced List Handling', hours: 0, minutes: 45 },
        { title: 'State and Props in React Native', hours: 0, minutes: 40 },
        { title: 'Managing State with Hooks in React Native', hours: 0, minutes: 50 },
        { title: 'Navigation in React Native (React Navigation)', hours: 0, minutes: 45 },
        { title: 'Stack, Tab, and Drawer Navigation', hours: 0, minutes: 45 },
        { title: 'Handling Gestures in React Native', hours: 0, minutes: 30 },
        { title: 'Using the Platform Module', hours: 0, minutes: 40 },
        { title: 'Handling Screen Orientation and Dimensions', hours: 0, minutes: 40 },
        { title: 'Working with the React Native CLI', hours: 0, minutes: 40 },
        { title: 'Using Expo for React Native Development', hours: 0, minutes: 40 },
        { title: 'React Native Animations (Animated API)', hours: 0, minutes: 50 },
        { title: 'Handling Media in React Native (Images, Videos, Audio)', hours: 0, minutes: 45 },
        { title: 'Working with React Native Forms and Inputs', hours: 0, minutes: 40 },
        { title: 'Fetching Data from APIs (Axios, Fetch)', hours: 0, minutes: 50 },
        { title: 'Networking and HTTP Requests', hours: 0, minutes: 40 },
        { title: 'State Management with Context API', hours: 0, minutes: 40 },
        { title: 'State Management with Redux in React Native', hours: 0, minutes: 50 },
        { title: 'Working with React Native AsyncStorage', hours: 0, minutes: 40 },
        { title: 'Introduction to React Native Firebase', hours: 0, minutes: 50 },
        { title: 'Integrating Third-Party Libraries', hours: 0, minutes: 40 },
        { title: 'Accessing Device Features (Camera, Location, etc.)', hours: 0, minutes: 50 },
        { title: 'Working with Native Modules and Components', hours: 0, minutes: 40 },
        { title: 'Debugging React Native Applications', hours: 0, minutes: 40 },
        { title: 'Testing in React Native (Jest, Detox)', hours: 0, minutes: 50 },
        { title: 'Performance Optimization in React Native', hours: 0, minutes: 45 },
        { title: 'Handling Push Notifications in React Native', hours: 0, minutes: 50 },
        { title: 'Offline Functionality in React Native Apps', hours: 0, minutes: 45 },
        { title: 'Deploying React Native Apps to App Stores (iOS, Android)', hours: 0, minutes: 50 },
        { title: 'Version Control and CI/CD with React Native', hours: 0, minutes: 40 },
        { title: 'React Native and TypeScript Integration', hours: 0, minutes: 50 },
        { title: 'Introduction to React Native Web', hours: 0, minutes: 40 },
        { title: 'React Native and Native Code (Objective-C/Java)', hours: 0, minutes: 50 },
        { title: 'Handling Navigation and Deep Linking', hours: 0, minutes: 40 },
        { title: 'Accessibility in React Native', hours: 0, minutes: 40 },
        { title: 'React Native and Animations (Lottie, Reanimated)', hours: 0, minutes: 50 },
        { title: 'Security Best Practices in React Native', hours: 0, minutes: 45 },
        { title: 'Building and Distributing Cross-Platform Apps', hours: 0, minutes: 50 },
        { title: 'Final Project: Building a Complete Cross-Platform App with React Native', hours: 2, minutes: 0 },
      ],
      technologies: ['reactNative'],
      showCourse: true,
      skills: [
        'Understanding the fundamentals of React Native and mobile app development',
        'Building and styling user interfaces with React Native components',
        'Managing application state effectively using Context API and Redux',
        'Integrating APIs and handling data fetching',
        'Utilizing device features and capabilities in mobile applications',
        'Testing and debugging React Native applications',
        'Deploying applications to both iOS and Android platforms',
        'Optimizing app performance for a smooth user experience',
      ],
      courseInfo: [
        {
          iconClass: 'fas fa-laptop-code',
          label: '8+ Skills',
        },
        {
          iconClass: 'fas fa-clock',
          label: 'Approximately 36 hours',
        },
        {
          iconClass: 'fas fa-user',
          label: 'Intermediate to Advanced',
        },
      ],
      companies : [
        { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
        { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
        { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
      ]
    },
    generativeAI: {
      title: 'Generative AI',
      description: 'This course covers the fundamental and advanced skills needed to understand and implement generative AI models and applications.',
      icon: 'FaRobot', // Consider using a suitable icon for Generative AI
      image: generativeAIImage,
      buttonText: 'Enroll Now',
      hours: '46',
      link: '/courseDetails?courseName=generativeAI',
      syllabus: [
        { title: 'Introduction to Generative AI', hours: 0, minutes: 40 },
        { title: 'Understanding the Basics of AI and Machine Learning', hours: 0, minutes: 50 },
        { title: 'Types of Generative AI Models', hours: 0, minutes: 40 },
        { title: 'Introduction to Neural Networks', hours: 0, minutes: 50 },
        { title: 'Supervised vs Unsupervised Learning', hours: 0, minutes: 50 },
        { title: 'What is a Generative Model?', hours: 0, minutes: 50 },
        { title: 'Generative Adversarial Networks (GANs)', hours: 0, minutes: 60 },
        { title: 'Understanding GAN Architecture (Generator, Discriminator)', hours: 0, minutes: 60 },
        { title: 'Training a GAN: How It Works', hours: 0, minutes: 60 },
        { title: 'Applications of GANs (Image, Video, and Audio Generation)', hours: 0, minutes: 50 },
        { title: 'Variational Autoencoders (VAEs)', hours: 0, minutes: 40 },
        { title: 'Difference Between VAEs and GANs', hours: 0, minutes: 40 },
        { title: 'Creating Images with GANs and VAEs', hours: 0, minutes: 60 },
        { title: 'Text Generation with Recurrent Neural Networks (RNNs)', hours: 0, minutes: 60 },
        { title: 'Introduction to Transformer Models', hours: 0, minutes: 50 },
        { title: 'Sequence-to-Sequence Models for Text Generation', hours: 0, minutes: 50 },
        { title: 'Natural Language Processing (NLP) in Generative AI', hours: 0, minutes: 60 },
        { title: 'GPT (Generative Pre-trained Transformer) Models', hours: 0, minutes: 60 },
        { title: 'Building Chatbots with GPT', hours: 0, minutes: 60 },
        { title: 'Large Language Models (LLMs)', hours: 0, minutes: 60 },
        { title: 'Fine-Tuning Pre-trained Generative Models', hours: 0, minutes: 50 },
        { title: 'Image Generation with Diffusion Models', hours: 0, minutes: 60 },
        { title: 'Deep Dream and Neural Style Transfer', hours: 0, minutes: 50 },
        { title: 'Audio and Music Generation with Generative AI', hours: 0, minutes: 60 },
        { title: 'Speech Synthesis and Voice Cloning', hours: 0, minutes: 60 },
        { title: 'Data Augmentation Using Generative Models', hours: 0, minutes: 50 },
        { title: 'Generative AI for Text-to-Image Translation', hours: 0, minutes: 60 },
        { title: 'Ethical Considerations in Generative AI', hours: 0, minutes: 50 },
        { title: 'Bias and Fairness in Generative Models', hours: 0, minutes: 50 },
        { title: 'Generative AI in Art and Creativity', hours: 0, minutes: 60 },
        { title: 'Real-World Applications of Generative AI in Business', hours: 0, minutes: 60 },
        { title: 'Using Generative AI for Data Synthesis', hours: 0, minutes: 50 },
        { title: 'Introduction to Reinforcement Learning in Generative AI', hours: 0, minutes: 60 },
        { title: 'Controlling Outputs of Generative Models', hours: 0, minutes: 60 },
        { title: 'Adversarial Attacks on Generative Models', hours: 0, minutes: 50 },
        { title: 'Evaluating the Performance of Generative Models', hours: 0, minutes: 60 },
        { title: 'Metrics for Evaluating Image and Text Generation', hours: 0, minutes: 50 },
        { title: 'Deploying Generative AI Models in Production', hours: 0, minutes: 60 },
        { title: 'Introduction to DeepFake Technology', hours: 0, minutes: 60 },
        { title: 'Generative AI for Video Synthesis', hours: 0, minutes: 60 },
        { title: 'Collaborating with Generative AI in Design and Animation', hours: 0, minutes: 50 },
        { title: 'Generative AI for Drug Discovery and Healthcare', hours: 0, minutes: 60 },
        { title: 'Privacy and Security in Generative AI', hours: 0, minutes: 50 },
        { title: 'Training Large Generative Models: Infrastructure and Costs', hours: 0, minutes: 50 },
        { title: 'Generative AI with OpenAI, Google DeepMind, and Meta AI Tools', hours: 0, minutes: 60 },
        { title: 'Using Cloud Services for Generative AI (AWS, Google Cloud, Azure)', hours: 0, minutes: 60 },
        { title: 'Generative AI and the Future of Content Creation', hours: 0, minutes: 60 },
        { title: 'Legal and Copyright Issues in Generative AI', hours: 0, minutes: 60 },
        { title: 'Building Ethical and Responsible Generative AI Models', hours: 0, minutes: 60 },
        { title: 'Final Project: Building a Generative AI Model (Text, Image, or Audio)', hours: 2, minutes: 0 },
      ], 
      technologies: ['generativeAI'],
      showCourse: true,
      skills: [
        'Understanding generative AI models and their architectures',
        'Implementing GANs and VAEs for various applications',
        'Creating text and image generation models',
        'Utilizing transformer models for advanced text generation',
        'Building applications using generative AI tools and frameworks',
        'Evaluating generative models and addressing ethical considerations',
        'Deploying generative AI models in real-world scenarios',
      ],
      courseInfo: [
        {
          iconClass: 'fas fa-laptop-code',
          label: '7 Skills',
        },
        {
          iconClass: 'fas fa-clock',
          label: 'Approximately 46 hours',
        },
        {
          iconClass: 'fas fa-user',
          label: 'Intermediate to Advanced',
        },
      ],
      companies : [
        { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
        { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
        { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
      ]
    },
    promptEngineering: {
      title: 'Prompt Engineering',
      description: 'This course covers the fundamental and advanced skills needed for effective prompt engineering in generative AI models.',
      icon: 'FaRobot', // Consider using a suitable icon for Prompt Engineering
      image: promptEngineeringImage,
      buttonText: 'Enroll Now',
      hours: '23',
      link: '/courseDetails?courseName=promptEngineering',
      syllabus: [
        { title: 'Introduction to Prompt Engineering', hours: 0, minutes: 30 },
        { title: 'Understanding Generative AI Models (GPT, DALL-E, etc.)', hours: 0, minutes: 45 },
        { title: 'What is a Prompt?', hours: 0, minutes: 30 },
        { title: 'Basic Structure of a Prompt', hours: 0, minutes: 45 },
        { title: 'Types of Prompts: Text, Image, Audio, and Code', hours: 0, minutes: 45 },
        { title: 'Text Prompts for Natural Language Processing (NLP) Models', hours: 0, minutes: 40 },
        { title: 'Image Prompts for Text-to-Image Models', hours: 0, minutes: 45 },
        { title: 'Understanding Tokenization in Prompts', hours: 0, minutes: 35 },
        { title: 'Controlling Model Output with Prompts', hours: 0, minutes: 40 },
        { title: 'Prompt Construction: Key Components', hours: 0, minutes: 45 },
        { title: 'Designing Effective Prompts', hours: 0, minutes: 45 },
        { title: 'Role of Context in Prompts', hours: 0, minutes: 35 },
        { title: 'Leveraging Pre-trained Models with Prompts', hours: 0, minutes: 40 },
        { title: 'Fine-Tuning Prompts for Specific Outputs', hours: 0, minutes: 40 },
        { title: 'Iterative Refinement of Prompts', hours: 0, minutes: 40 },
        { title: 'Prompt Engineering for Conversational AI', hours: 0, minutes: 45 },
        { title: 'Handling Ambiguity in Prompts', hours: 0, minutes: 35 },
        { title: 'Natural Language Inference with Prompts', hours: 0, minutes: 40 },
        { title: 'Multi-Turn Prompts for Complex Dialogues', hours: 0, minutes: 45 },
        { title: 'Zero-Shot vs Few-Shot Learning with Prompts', hours: 0, minutes: 40 },
        { title: 'Prompt Chaining: Creating Sequential Prompts', hours: 0, minutes: 45 },
        { title: 'Task-Specific Prompting (Summarization, Translation, etc.)', hours: 0, minutes: 40 },
        { title: 'Incorporating Constraints in Prompts', hours: 0, minutes: 35 },
        { title: 'Evaluating Prompt Quality', hours: 0, minutes: 35 },
        { title: 'Handling Failure Cases in Prompt Responses', hours: 0, minutes: 35 },
        { title: 'Bias and Ethical Considerations in Prompts', hours: 0, minutes: 40 },
        { title: 'Testing and Debugging Prompts', hours: 0, minutes: 35 },
        { title: 'Optimizing Prompt Length and Structure', hours: 0, minutes: 40 },
        { title: 'Prompt Strategies for Large Language Models', hours: 0, minutes: 40 },
        { title: 'Structured Prompts for Data Generation', hours: 0, minutes: 35 },
        { title: 'Combining Prompts for Multi-Modal Outputs', hours: 0, minutes: 40 },
        { title: 'Context-Aware Prompting for Specific Domains', hours: 0, minutes: 35 },
        { title: 'Enhancing Creativity with Prompt Engineering', hours: 0, minutes: 35 },
        { title: 'Best Practices for Prompt Engineering', hours: 0, minutes: 45 },
        { title: 'Final Project: Designing Prompts for a Real-World Application', hours: 1, minutes: 0 }
      ],
      technologies: ['promptEngineering'],
      showCourse: true,
      skills: [
        'Understanding the fundamentals of prompt engineering and its importance',
        'Designing effective prompts for various generative AI models',
        'Utilizing context and constraints to refine prompts',
        'Evaluating and optimizing prompts for performance',
        'Incorporating ethical considerations in prompt design',
        'Implementing advanced techniques for multi-modal outputs',
        'Testing and debugging prompts effectively',
        'Collaborating with AI systems through prompts for enhanced creativity',
      ],
      courseInfo: [
        {
          iconClass: 'fas fa-robot',
          label: '8 Skills',
        },
        {
          iconClass: 'fas fa-clock',
          label: 'Approximately 23 hours',
        },
        {
          iconClass: 'fas fa-user',
          label: 'Intermediate to Advanced',
        },
      ],
      companies : [
        { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
        { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
        { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
      ]
    },
    machineLearning: {
      title: 'Machine Learning',
      description: 'This course covers the fundamental and advanced skills needed to understand and implement machine learning algorithms and techniques.',
      icon: 'FaBrain', // Consider using a suitable icon for Machine Learning
      image: machineLearningImage,
      buttonText: 'Enroll Now',
      hours: '73',
      link: '/courseDetails?courseName=machineLearning',
      syllabus: [
        { title: 'Introduction to Machine Learning', hours: 1, minutes: 0 },
        { title: 'History and Evolution of Machine Learning', hours: 0, minutes: 45 },
        { title: 'Types of Machine Learning (Supervised, Unsupervised, Reinforcement)', hours: 1, minutes: 0 },
        { title: 'The Machine Learning Workflow', hours: 0, minutes: 50 },
        { title: 'Setting Up the Machine Learning Environment', hours: 0, minutes: 40 },
        { title: 'Python/R for Machine Learning', hours: 0, minutes: 45 },
        { title: 'Data Preprocessing and Feature Engineering', hours: 1, minutes: 10 },
        { title: 'Handling Missing Data', hours: 0, minutes: 40 },
        { title: 'Data Normalization and Standardization', hours: 0, minutes: 45 },
        { title: 'Introduction to Supervised Learning', hours: 1, minutes: 0 },
        { title: 'Linear Regression', hours: 1, minutes: 0 },
        { title: 'Logistic Regression', hours: 1, minutes: 0 },
        { title: 'K-Nearest Neighbors (KNN)', hours: 0, minutes: 50 },
        { title: 'Support Vector Machines (SVM)', hours: 1, minutes: 0 },
        { title: 'Decision Trees', hours: 1, minutes: 0 },
        { title: 'Random Forests', hours: 0, minutes: 50 },
        { title: 'Naive Bayes Classifier', hours: 0, minutes: 50 },
        { title: 'Gradient Boosting Machines (GBM)', hours: 1, minutes: 0 },
        { title: 'XGBoost, LightGBM, CatBoost', hours: 1, minutes: 10 },
        { title: 'Ensemble Learning (Bagging, Boosting, Stacking)', hours: 1, minutes: 0 },
        { title: 'Introduction to Unsupervised Learning', hours: 0, minutes: 50 },
        { title: 'K-Means Clustering', hours: 0, minutes: 55 },
        { title: 'Hierarchical Clustering', hours: 0, minutes: 55 },
        { title: 'Principal Component Analysis (PCA)', hours: 1, minutes: 0 },
        { title: 'Singular Value Decomposition (SVD)', hours: 0, minutes: 50 },
        { title: 'Dimensionality Reduction Techniques', hours: 1, minutes: 0 },
        { title: 'Association Rule Learning (Apriori, Eclat)', hours: 1, minutes: 0 },
        { title: 'Anomaly Detection', hours: 0, minutes: 55 },
        { title: 'Reinforcement Learning Basics', hours: 1, minutes: 0 },
        { title: 'Markov Decision Processes (MDPs)', hours: 0, minutes: 50 },
        { title: 'Q-Learning', hours: 1, minutes: 0 },
        { title: 'Deep Q-Networks (DQN)', hours: 0, minutes: 55 },
        { title: 'Introduction to Artificial Neural Networks (ANN)', hours: 1, minutes: 10 },
        { title: 'Backpropagation and Gradient Descent', hours: 1, minutes: 0 },
        { title: 'Activation Functions (ReLU, Sigmoid, Softmax)', hours: 0, minutes: 50 },
        { title: 'Convolutional Neural Networks (CNN)', hours: 1, minutes: 10 },
        { title: 'Recurrent Neural Networks (RNN)', hours: 0, minutes: 55 },
        { title: 'Long Short-Term Memory (LSTM) Networks', hours: 0, minutes: 50 },
        { title: 'Generative Adversarial Networks (GANs)', hours: 1, minutes: 10 },
        { title: 'Autoencoders', hours: 0, minutes: 55 },
        { title: 'Transfer Learning', hours: 1, minutes: 0 },
        { title: 'Time Series Forecasting in Machine Learning', hours: 1, minutes: 0 },
        { title: 'Natural Language Processing (NLP)', hours: 1, minutes: 10 },
        { title: 'Text Classification and Sentiment Analysis', hours: 1, minutes: 0 },
        { title: 'Tokenization and Word Embeddings (Word2Vec, GloVe)', hours: 0, minutes: 55 },
        { title: 'Sequence Models in NLP', hours: 1, minutes: 0 },
        { title: 'Hyperparameter Tuning (Grid Search, Random Search)', hours: 1, minutes: 10 },
        { title: 'Cross-Validation Techniques', hours: 1, minutes: 0 },
        { title: 'Bias-Variance Tradeoff', hours: 0, minutes: 55 },
        { title: 'Overfitting and Underfitting', hours: 0, minutes: 50 },
        { title: 'Regularization Techniques (L1, L2, ElasticNet)', hours: 0, minutes: 55 },
        { title: 'Model Evaluation Metrics (Accuracy, Precision, Recall, F1 Score)', hours: 1, minutes: 0 },
        { title: 'Confusion Matrix and ROC Curve', hours: 0, minutes: 55 },
        { title: 'Precision-Recall Tradeoff', hours: 0, minutes: 55 },
        { title: 'Handling Imbalanced Datasets', hours: 1, minutes: 0 },
        { title: 'Introduction to Bayesian Learning', hours: 1, minutes: 0 },
        { title: 'Bayesian Networks', hours: 0, minutes: 55 },
        { title: 'Markov Chains and Hidden Markov Models (HMM)', hours: 1, minutes: 0 },
        { title: 'Optimization Algorithms (Adam, RMSprop, SGD)', hours: 1, minutes: 0 },
        { title: 'Model Interpretability and Explainability', hours: 1, minutes: 0 },
        { title: 'Explainable AI (XAI)', hours: 0, minutes: 55 },
        { title: 'Ethics and Bias in Machine Learning', hours: 1, minutes: 0 },
        { title: 'Introduction to AutoML', hours: 1, minutes: 0 },
        { title: 'Machine Learning in Big Data', hours: 1, minutes: 0 },
        { title: 'Distributed Machine Learning with Hadoop/Spark', hours: 1, minutes: 10 },
        { title: 'Working with Cloud ML Platforms (AWS, Azure, Google Cloud)', hours: 0, minutes: 55 },
        { title: 'Deploying Machine Learning Models', hours: 1, minutes: 0 },
        { title: 'Monitoring and Maintaining ML Models in Production', hours: 1, minutes: 0 },
        { title: 'Machine Learning for IoT', hours: 1, minutes: 0 },
        { title: 'Edge Machine Learning', hours: 1, minutes: 0 },
        { title: 'Adversarial Machine Learning', hours: 1, minutes: 0 },
        { title: 'Introduction to Quantum Machine Learning', hours: 1, minutes: 10 },
        { title: 'Real-World Case Studies in Machine Learning', hours: 1, minutes: 0 },
        { title: 'Best Practices in Machine Learning Projects', hours: 1, minutes: 0 },
        { title: 'Final Project: Building and Deploying a Machine Learning Model', hours: 2, minutes: 0 },
      ],
      technologies: ['machineLearning'],
      showCourse: true,
      skills: [
        'Understanding core concepts and types of machine learning',
        'Implementing supervised and unsupervised learning algorithms',
        'Working with advanced models like neural networks and ensemble methods',
        'Data preprocessing, feature engineering, and handling missing data',
        'Applying machine learning techniques in real-world applications',
        'Evaluating and optimizing model performance and accuracy',
        'Understanding ethical considerations and biases in machine learning',
        'Deploying machine learning models to production environments',
      ],
      courseInfo: [
        {
          iconClass: 'fas fa-brain',
          label: '8 Skills',
        },
        {
          iconClass: 'fas fa-clock',
          label: 'Approximately 73 hours',
        },
        {
          iconClass: 'fas fa-user',
          label: 'Intermediate to Advanced',
        },
      ],
      companies : [
        { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
        { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
        { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
      ]
    },
    dataScience: {
      title: 'Data Science',
      description: 'This course covers the fundamental and advanced skills needed to analyze and interpret complex data to inform business decisions.',
      icon: 'FaChartLine', // Consider using a suitable icon for Data Science
      image: dataScienceImage,
      buttonText: 'Enroll Now',
      hours: '68',
      link: '/courseDetails?courseName=dataScience',
      syllabus: [
        { title: 'Introduction to Data Science', hours: 1, minutes: 0 },
        { title: 'The Data Science Workflow', hours: 0, minutes: 45 },
        { title: 'Programming for Data Science (Python/R)', hours: 1, minutes: 0 },
        { title: 'Data Types and Structures', hours: 0, minutes: 50 },
        { title: 'Data Acquisition and Sources', hours: 0, minutes: 50 },
        { title: 'Exploratory Data Analysis (EDA)', hours: 1, minutes: 0 },
        { title: 'Data Wrangling and Cleaning', hours: 1, minutes: 10 },
        { title: 'Handling Missing Data', hours: 0, minutes: 50 },
        { title: 'Data Normalization and Standardization', hours: 0, minutes: 50 },
        { title: 'Feature Engineering', hours: 1, minutes: 0 },
        { title: 'Feature Selection', hours: 0, minutes: 55 },
        { title: 'Data Visualization Techniques', hours: 1, minutes: 0 },
        { title: 'Statistical Analysis for Data Science', hours: 0, minutes: 55 },
        { title: 'Probability Theory and Distributions', hours: 1, minutes: 0 },
        { title: 'Hypothesis Testing', hours: 0, minutes: 50 },
        { title: 'Linear Algebra for Data Science', hours: 1, minutes: 0 },
        { title: 'Data Modeling and Prediction', hours: 1, minutes: 0 },
        { title: 'Introduction to Machine Learning', hours: 1, minutes: 0 },
        { title: 'Supervised vs Unsupervised Learning', hours: 0, minutes: 55 },
        { title: 'Linear Regression', hours: 1, minutes: 0 },
        { title: 'Logistic Regression', hours: 1, minutes: 0 },
        { title: 'Decision Trees', hours: 1, minutes: 0 },
        { title: 'Random Forests', hours: 1, minutes: 0 },
        { title: 'Support Vector Machines (SVM)', hours: 1, minutes: 0 },
        { title: 'K-Nearest Neighbors (KNN)', hours: 0, minutes: 55 },
        { title: 'Naive Bayes', hours: 0, minutes: 50 },
        { title: 'K-Means Clustering', hours: 0, minutes: 55 },
        { title: 'Hierarchical Clustering', hours: 0, minutes: 55 },
        { title: 'Dimensionality Reduction (PCA, LDA)', hours: 1, minutes: 0 },
        { title: 'Introduction to Neural Networks', hours: 1, minutes: 0 },
        { title: 'Deep Learning Basics', hours: 1, minutes: 0 },
        { title: 'Time Series Analysis', hours: 1, minutes: 0 },
        { title: 'Natural Language Processing (NLP)', hours: 1, minutes: 0 },
        { title: 'Sentiment Analysis', hours: 0, minutes: 50 },
        { title: 'Recommender Systems', hours: 0, minutes: 50 },
        { title: 'Big Data and Data Science', hours: 1, minutes: 0 },
        { title: 'Introduction to Hadoop', hours: 1, minutes: 0 },
        { title: 'Introduction to Spark', hours: 1, minutes: 0 },
        { title: 'Data Science with SQL', hours: 1, minutes: 0 },
        { title: 'NoSQL Databases for Data Science', hours: 0, minutes: 50 },
        { title: 'Model Evaluation Techniques', hours: 1, minutes: 0 },
        { title: 'Accuracy, Precision, Recall, F1 Score', hours: 0, minutes: 55 },
        { title: 'Confusion Matrix', hours: 0, minutes: 50 },
        { title: 'Cross-Validation', hours: 1, minutes: 0 },
        { title: 'Overfitting and Underfitting', hours: 0, minutes: 50 },
        { title: 'Hyperparameter Tuning', hours: 1, minutes: 0 },
        { title: 'Ensemble Methods (Bagging, Boosting, Stacking)', hours: 1, minutes: 0 },
        { title: 'Introduction to AI and Machine Learning', hours: 1, minutes: 0 },
        { title: 'Deploying Machine Learning Models', hours: 1, minutes: 0 },
        { title: 'Introduction to Data Science in Cloud (AWS, Google Cloud, Azure)', hours: 1, minutes: 0 },
        { title: 'Automated Machine Learning (AutoML)', hours: 1, minutes: 0 },
        { title: 'Data Science Best Practices', hours: 1, minutes: 0 },
        { title: 'Ethics and Privacy in Data Science', hours: 1, minutes: 0 },
        { title: 'Real-World Data Science Case Studies', hours: 1, minutes: 0 },
        { title: 'Building and Deploying Data Science Projects', hours: 1, minutes: 0 },
        { title: 'Data Science for Business Insights', hours: 1, minutes: 0 },
        { title: 'Time Series Forecasting', hours: 1, minutes: 0 },
        { title: 'Introduction to Deep Learning Frameworks (TensorFlow, PyTorch)', hours: 1, minutes: 0 },
        { title: 'Optimization Algorithms in Data Science', hours: 1, minutes: 0 },
        { title: 'Working with Geospatial Data', hours: 1, minutes: 0 },
        { title: 'Anomaly Detection', hours: 0, minutes: 50 },
        { title: 'Data Science for Healthcare', hours: 1, minutes: 0 },
        { title: 'Reinforcement Learning Basics', hours: 1, minutes: 0 },
        { title: 'Explainable AI (XAI) in Data Science', hours: 1, minutes: 0 },
        { title: 'Introduction to Graph Data Science', hours: 1, minutes: 0 },
        { title: 'Data Science in Finance', hours: 1, minutes: 0 },
        { title: 'Bias and Fairness in Machine Learning', hours: 1, minutes: 0 },
        { title: 'Data Engineering for Data Science', hours: 1, minutes: 0 },
        { title: 'Edge Computing in Data Science', hours: 1, minutes: 0 },
        { title: 'Introduction to Quantum Computing in Data Science', hours: 1, minutes: 0 },
        { title: 'Data Science Project Management', hours: 1, minutes: 0 },
        { title: 'Future Trends in Data Science', hours: 1, minutes: 0 },
      ],
      
      technologies: ['dataScience'],
      showCourse: true,
      skills: [
        'Understanding the data science workflow and key concepts',
        'Implementing data preprocessing and feature engineering techniques',
        'Applying statistical analysis and visualization techniques',
        'Utilizing machine learning algorithms for predictive modeling',
        'Working with big data tools and cloud platforms',
        'Evaluating and optimizing machine learning models',
        'Understanding ethical considerations in data science',
        'Building and deploying data science projects in real-world scenarios',
      ],
      courseInfo: [
        {
          iconClass: 'fas fa-chart-line',
          label: '8 Skills',
        },
        {
          iconClass: 'fas fa-clock',
          label: 'Approximately 68 hours',
        },
        {
          iconClass: 'fas fa-user',
          label: 'Intermediate to Advanced',
        },
      ],
      companies : [
        { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
        { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
        { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
      ]
    },
    digitalMarketing : {
      title: 'Digital Marketing',
      description: 'Become a digital marketing expert by mastering SEO, social media, email marketing, and more to grow businesses online.',
      icon: 'FaBullhorn', // Choose a suitable icon for Digital Marketing
      image: digitalMarketingImage, // Add the appropriate image here
      buttonText: 'Enroll Now',
      hours: '27',
      link: '/courseDetails?courseName=digitalMarketing',
      syllabus: [
        { title: 'Introduction to Digital Marketing', hours: 1, minutes: 0 },
        { title: 'Marketing Fundamentals', hours: 1, minutes: 0 },
        { title: 'Search Engine Optimization (SEO) Basics', hours: 2, minutes: 0 },
        { title: 'Advanced SEO Techniques', hours: 1, minutes: 30 },
        { title: 'Content Marketing Strategy', hours: 1, minutes: 0 },
        { title: 'Introduction to Social Media Marketing', hours: 1, minutes: 30 },
        { title: 'Social Media Advertising (Facebook, Instagram, LinkedIn)', hours: 2, minutes: 0 },
        { title: 'Google Ads (PPC)', hours: 2, minutes: 0 },
        { title: 'Email Marketing Fundamentals', hours: 1, minutes: 0 },
        { title: 'Creating Effective Email Campaigns', hours: 1, minutes: 0 },
        { title: 'Google Analytics for Digital Marketing', hours: 2, minutes: 0 },
        { title: 'Conversion Rate Optimization (CRO)', hours: 1, minutes: 30 },
        { title: 'Affiliate Marketing', hours: 1, minutes: 0 },
        { title: 'Mobile Marketing', hours: 1, minutes: 0 },
        { title: 'Building an Online Community', hours: 1, minutes: 0 },
        { title: 'Video Marketing (YouTube, TikTok)', hours: 1, minutes: 0 },
        { title: 'Measuring Digital Marketing ROI', hours: 1, minutes: 30 },
        { title: 'Digital Marketing Strategy and Planning', hours: 2, minutes: 0 },
        { title: 'Introduction to Influencer Marketing', hours: 1, minutes: 0 },
        { title: 'Real-world Case Studies in Digital Marketing', hours: 1, minutes: 30 }
      ],
      
      technologies: ['digitalMarketing'],
      showCourse: true,
      skills: [
        'Mastering SEO for organic search traffic growth',
        'Running effective social media and PPC campaigns',
        'Utilizing Google Analytics to measure marketing performance',
        'Developing high-converting email marketing strategies',
        'Understanding content marketing and its impact',
        'Building digital marketing campaigns across multiple channels',
        'Optimizing campaigns for conversions and ROI',
        'Implementing strategies for long-term digital marketing success'
      ],
      courseInfo: [
        {
          iconClass: 'fas fa-bullhorn',
          label: 'Master Multiple Platforms'
        },
        {
          iconClass: 'fas fa-calendar-alt',
          label: 'Approximately 27 hours'
        },
        {
          iconClass: 'fas fa-user',
          label: 'Suitable for Beginners and Marketing Professionals'
        }
      ],
      companies: [
        { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
        { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
        { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
      ]
    },
    manualTesting : {
      title: 'Manual Testing',
      description: 'Learn the fundamentals of manual testing, from test case creation to defect reporting, to ensure the quality of software applications.',
      icon: 'FaClipboardCheck', // You can choose a suitable icon for Manual Testing
      image: manualTestingImage, // Add the appropriate image here
      buttonText: 'Enroll Now',
      hours: '24',
      link: '/courseDetails?courseName=manualTesting',
      syllabus: [
        { title: 'Introduction to Software Testing', hours: 1, minutes: 0 },
        { title: 'Understanding the SDLC and STLC', hours: 1, minutes: 0 },
        { title: 'Types of Software Testing', hours: 1, minutes: 0 },
        { title: 'Test Plan and Test Strategy', hours: 1, minutes: 0 },
        { title: 'Writing Test Cases', hours: 1, minutes: 0 },
        { title: 'Test Case Execution', hours: 1, minutes: 0 },
        { title: 'Defect Life Cycle', hours: 1, minutes: 0 },
        { title: 'Test Management Tools (Jira, QC)', hours: 1, minutes: 0 },
        { title: 'Exploratory Testing', hours: 0, minutes: 45 },
        { title: 'Functional Testing', hours: 1, minutes: 0 },
        { title: 'Regression Testing', hours: 1, minutes: 0 },
        { title: 'Smoke and Sanity Testing', hours: 0, minutes: 45 },
        { title: 'User Acceptance Testing (UAT)', hours: 1, minutes: 0 },
        { title: 'API Testing Basics', hours: 1, minutes: 0 },
        { title: 'Mobile Application Testing', hours: 1, minutes: 30 },
        { title: 'Performance Testing Overview', hours: 1, minutes: 0 },
        { title: 'Cross-browser Testing', hours: 1, minutes: 0 },
        { title: 'Testing Best Practices', hours: 1, minutes: 0 },
        { title: 'Introduction to Agile Testing', hours: 1, minutes: 0 },
        { title: 'Creating Bug Reports', hours: 1, minutes: 0 },
        { title: 'Test Reporting and Metrics', hours: 1, minutes: 0 },
        { title: 'End-to-End Testing for Web and Mobile', hours: 1, minutes: 30 },
        { title: 'Real-world Case Studies in Manual Testing', hours: 1, minutes: 30 }
      ],      
      technologies: ['manualTesting'],
      showCourse: true,
      skills: [
        'Understanding the software testing life cycle (STLC)',
        'Writing and executing test cases effectively',
        'Defining and managing defects',
        'Understanding various types of testing such as functional, regression, and UAT',
        'Using test management tools like Jira and Quality Center',
        'Ensuring quality in web, mobile, and API testing',
        'Creating comprehensive test documentation and reports',
        'Collaborating in Agile teams for testing in sprints'
      ],
      courseInfo: [
        {
          iconClass: 'fas fa-clipboard-check',
          label: 'Core Manual Testing Skills'
        },
        {
          iconClass: 'fas fa-clock',
          label: 'Approximately 24 hours'
        },
        {
          iconClass: 'fas fa-user',
          label: 'Beginner-Friendly'
        }
      ],
      companies: [
        { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
        { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
        { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
      ]
    },
    automationTesting : {
      title: 'Automation Testing',
      description: 'Master automation testing techniques, tools, and frameworks to become an expert in test automation and quality assurance.',
      icon: 'FaRobot', // You can choose a suitable icon for Automation Testing
      image: automationTestingImage, // Add the appropriate image here
      buttonText: 'Enroll Now',
      hours: '40',
      link: '/courseDetails?courseName=automationTesting',
      syllabus: [
        { title: 'Introduction to Software Testing', hours: 1, minutes: 0 },
        { title: 'Manual vs Automation Testing', hours: 1, minutes: 0 },
        { title: 'Automation Testing Overview', hours: 1, minutes: 0 },
        { title: 'Introduction to Selenium', hours: 1, minutes: 0 },
        { title: 'Installing Selenium WebDriver', hours: 0, minutes: 30 },
        { title: 'Basic Selenium WebDriver Commands', hours: 0, minutes: 45 },
        { title: 'Test Automation Frameworks', hours: 1, minutes: 0 },
        { title: 'Creating Test Cases in Selenium', hours: 0, minutes: 45 },
        { title: 'Introduction to TestNG', hours: 1, minutes: 0 },
        { title: 'Data-Driven Testing', hours: 1, minutes: 0 },
        { title: 'Handling Dynamic Web Elements', hours: 1, minutes: 0 },
        { title: 'Synchronization in Selenium', hours: 0, minutes: 45 },
        { title: 'Automating Web Forms', hours: 1, minutes: 0 },
        { title: 'Working with Excel Files', hours: 1, minutes: 0 },
        { title: 'Test Automation for APIs (Postman)', hours: 1, minutes: 30 },
        { title: 'Advanced Selenium Features', hours: 1, minutes: 30 },
        { title: 'Introduction to Jenkins for CI/CD', hours: 1, minutes: 0 },
        { title: 'Integrating Selenium with Jenkins', hours: 1, minutes: 30 },
        { title: 'Reporting in Automation Testing', hours: 1, minutes: 0 },
        { title: 'Introduction to Appium (Mobile Testing)', hours: 1, minutes: 30 },
        { title: 'Automating Mobile Applications with Appium', hours: 2, minutes: 0 },
        { title: 'Performance Testing Overview (JMeter)', hours: 1, minutes: 30 },
        { title: 'API Automation with RestAssured', hours: 2, minutes: 0 },
        { title: 'Behavior-Driven Development (BDD) with Cucumber', hours: 1, minutes: 30 },
        { title: 'CI/CD Pipelines for Test Automation', hours: 1, minutes: 30 },
        { title: 'End-to-End Testing Strategies', hours: 1, minutes: 30 },
        { title: 'Best Practices in Test Automation', hours: 1, minutes: 30 },
        { title: 'Advanced Test Automation Frameworks', hours: 2, minutes: 0 },
        { title: 'Continuous Integration in Testing', hours: 2, minutes: 0 },
        { title: 'Test Automation for Web, Mobile, and API', hours: 2, minutes: 0 },
        { title: 'Real-World Case Studies in Test Automation', hours: 2, minutes: 0 }
      ],
      
      technologies: ['automationTesting'],
      showCourse: true,
      skills: [
        'Understanding the fundamentals of software testing',
        'Proficiency in Selenium WebDriver for web automation',
        'Creating and managing automated test scripts',
        'Understanding test automation frameworks',
        'Working with continuous integration tools like Jenkins',
        'Automating web, mobile, and API testing',
        'Implementing data-driven and BDD testing',
        'Advanced reporting and logging in automation testing'
      ],
      courseInfo: [
        {
          iconClass: 'fas fa-robot',
          label: '10+ Tools Covered'
        },
        {
          iconClass: 'fas fa-clock',
          label: 'Approximately 40 hours'
        },
        {
          iconClass: 'fas fa-user-check',
          label: 'Suitable for Beginners to Advanced Testers'
        }
      ],
      companies: [
        { name: 'Google', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Logo_2013_Google.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
        { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Microsoft_logo_%282012%29.svg' },
        { name: 'Amazon', logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Netflix', logo: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg' }
      ]
    }
    }

  