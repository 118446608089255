import React from 'react';
import Banner from './Banner/Banner';
import MissionVision from './MissionVision/MissionVision';
import CourseList from './CourseList/CourseList';
import TrainingVideo from './TrainingVideo/TrainingVideo';
import AboutUsComp from './AboutUs/AboutUs';
// import ClassroomTraining from './ClassroomTraining/ClassroomTraining';
import ChooseUS from './ChooseUS/ChooseUS';
import CourseFAQs from './CourseFAQs/CourseFAQs';
import Blogs from './Blogs/Blogs';
import ContactUs from './ContactUs/ContactUs';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const HomePage = () => {
  return (
    <>
      <Header />
      <Banner />
      <MissionVision />
      <TrainingVideo />
      <CourseList showAllCourses={false} />
      <AboutUsComp />
      {/* <ClassroomTraining /> */}
      <ChooseUS />
      <CourseFAQs />
      <Blogs />
      {/* <Gallery/> */}
      <ContactUs />
      <Footer />
    </>
  );
};

export default HomePage;
