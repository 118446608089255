import React from 'react';
import './CancellationPolicy.css'; // Ensure this CSS file is created to style the component
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

const CancellationPolicy = () => {
  return (
    <>
      <Header />
      <div className="cancellation-policy-container">
        <h1 className="page-title">Cancellation and Refund Policy</h1>
        <p>
          Effective Date:{' '}
          <span className="policy-update-date">06-sep-2024</span>
        </p>
        <p>
          At RuhiTech Solutions, we strive to provide the best possible training
          experience for our students and professionals. However, we understand
          that circumstances may arise where you need to cancel or reschedule
          your enrollment. Please carefully review our cancellation and refund
          policy below:
        </p>

        <h2>1. Cancellation Requests</h2>
        <p>
          To request a cancellation, please contact us via email at{' '}
          <a href="mailto:contact@ruhitechsolutions.com">
            contact@ruhitechsolutions.com
          </a>{' '}
          or call us at +91-8220343006 or in person at our office. We require a
          minimum of 7 days' notice before the course start date to process your
          cancellation request.
        </p>

        <h2>2. Refund Policy</h2>
        <ul>
          <li>
            <strong>Full Refund:</strong> If you cancel your enrollment at least
            7 days before the course start date, you are eligible for a full
            refund of the course fees.
          </li>
          <li>
            <strong>Partial Refund:</strong> If you cancel your enrollment
            within 7 days of the course start date, you are eligible for a 50%
            refund of the course fees.
          </li>
          <li>
            <strong>No Refund:</strong> No refunds will be provided for
            cancellations made on or after the course start date.
          </li>
        </ul>

        <h2>3. Rescheduling Policy</h2>
        <p>
          If you need to reschedule your course, please contact us at least 3
          days before the course start date. We will do our best to accommodate
          your request based on availability. A rescheduling fee of 10% of the
          course fee may apply.
        </p>

        <h2>4. No Show Policy</h2>
        <p>
          In the event that you do not attend the course and do not provide
          prior notice, no refunds or rescheduling options will be available.
        </p>

        <h2>5. Course Cancellation by RuhiTech Solutions</h2>
        <p>
          If RuhiTech Solutions cancels a course due to unforeseen
          circumstances, all participants will be notified promptly, and a full
          refund will be provided. Alternatively, participants may choose to
          enroll in a future course without additional fees.
        </p>

        <h2>6. Changes to the Policy</h2>
        <p>
          RuhiTech Solutions reserves the right to modify or update this
          Cancellation and Refund Policy at any time. Any changes will be
          communicated to participants via email or through our website.
        </p>

        <h2>7. Limitation of Liability</h2>
        <p>
          RuhiTech Solutions' liability is limited to the amount of the course
          fees paid by the participant. RuhiTech Solutions will not be liable
          for any indirect, consequential, or special damages, including loss of
          opportunity, even if advised of the possibility of such damages.
        </p>

        <h2>8. Governing Law</h2>
        <p>
          This policy shall be governed by and construed in accordance with the
          laws of India. Any disputes arising from this policy shall be subject
          to the exclusive jurisdiction of the courts in Chennai, Tamil Nadu.
        </p>

        <h2>Contact Information</h2>
        <p>
          If you have any questions or need further assistance, please contact
          us using the following details:
        </p>
        <ul>
          <li>
            Email:{' '}
            <a href="mailto:contact@ruhitechsolutions.com">
              contact@ruhitechsolutions.com
            </a>
          </li>
          <li>Phone: +91-8220343006</li>
          <li>
            Address: 89/15, 1st main road, Gandhi Nagar, Adyar, Chennai-600020
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
};

export default CancellationPolicy;
