import React from 'react';
import {
  FaHtml5,
  FaCss3Alt,
  FaJsSquare,
  FaBootstrap,
  FaReact,
  FaNodeJs,
  FaPython,
  FaDatabase,
  FaCode,
  FaRobot,
  FaJira,
  FaNetworkWired,
  FaCloud,
  FaLock,
  FaTools,
  FaCodeBranch,
  FaBug,
  FaFileAlt,
  FaTasks,
  FaSearch,
  FaGlobe,
  FaCog,
} from 'react-icons/fa';
import {
  SiJirasoftware,
  SiMongodb,
  SiExpress,
  SiWordpress,
  SiGoogleanalytics,
  SiJenkins,
  SiSelenium,
  SiPostman,
} from 'react-icons/si';
import { AiOutlineRobot } from 'react-icons/ai';
import { DiScrum } from 'react-icons/di';
import './CourseBanner.css';

const icons = [
  { Icon: FaHtml5, color: '#E44D26' },
  { Icon: FaCss3Alt, color: '#264DE4' },
  { Icon: FaJsSquare, color: '#F0DB4F' },
  { Icon: FaBootstrap, color: '#7952B3' },
  { Icon: FaReact, color: '#61DAFB' },
  { Icon: FaNodeJs, color: '#68A063' },
  { Icon: FaPython, color: '#306998' },
  { Icon: FaDatabase, color: '#4DB33D' },
  { Icon: FaCode, color: '#F7DF1E' },
  { Icon: FaRobot, color: '#FF7F50' },
  { Icon: FaJira, color: '#0052CC' },
  { Icon: SiJirasoftware, color: '#0052CC' },
  { Icon: SiMongodb, color: '#47A248' },
  { Icon: SiExpress, color: '#000000' },
  { Icon: SiWordpress, color: '#21759B' },
  { Icon: SiGoogleanalytics, color: '#E37400' },
  { Icon: SiJenkins, color: '#D33833' },
  { Icon: SiSelenium, color: '#43B02A' },
  { Icon: SiPostman, color: '#FF6C37' },
  { Icon: FaNetworkWired, color: '#3B5998' },
  { Icon: FaCloud, color: '#00ADEF' },
  { Icon: FaLock, color: '#FF6600' },
  { Icon: FaTools, color: '#FFC107' },
  { Icon: FaCodeBranch, color: '#8A2BE2' },
  { Icon: FaBug, color: '#D32F2F' },
  { Icon: FaFileAlt, color: '#F7C52B' },
  { Icon: FaTasks, color: '#4CAF50' },
  { Icon: FaSearch, color: '#FFC107' },
  { Icon: FaGlobe, color: '#4CAF50' },
  { Icon: FaCog, color: '#607D8B' },
  { Icon: AiOutlineRobot, color: '#FF6347' },
  { Icon: DiScrum, color: '#007ACC' },
];

const CourseBanner = ({ showAllCourses }) => {
  return (
    <div
      className="courseMain-banner"
      style={{ marginTop: showAllCourses ? '80px' : '0px' }}
    >
      <div className="courseMain-banner-content">
        <h1 className="page-title">Explore Our Courses</h1>
        <p className="courseMain-banner-subtitle">
          Master the skills you need to succeed
        </p>
      </div>
      {!showAllCourses && (
        <div className="courseMain-marquee">
          <div className="courseMain-marquee-content">
            {icons.map(({ Icon, color }, index) => (
              <div className="courseMain-icon-item" key={index}>
                <Icon className="courseMain-grid-icon" style={{ color }} />
              </div>
            ))}
            {icons.map(({ Icon, color }, index) => (
              <div className="courseMain-icon-item" key={`duplicate-${index}`}>
                <Icon className="courseMain-grid-icon" style={{ color }} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseBanner;
