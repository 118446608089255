import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from './reducers/modalSlice';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Components/HomePage';
import CoursePage from './Components/CoursePage';
import CourseLandingPage from './Components/CoursePage/CourseLanding/CourseLanding';
import ServicePage from './Components/ServicePage';
import BlogsPage from './Components/BlogsPage';
import ContactUsPage from './Components/ContactUsPage';
import BlogPost from './Components/BlogsPage/BlogPost/BlogPost';
import CancellationPolicy from './Components/UsefulLinks/CancellationPolicy/CancellationPolicy';
import TermsAndConditions from './Components/UsefulLinks/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './Components/UsefulLinks/PrivacyPolicy/PrivacyPolicy';
import CookiePolicy from './Components/UsefulLinks/CookiePolicy/CookiePolicy';
import PageNotFound from './Components/PageNotFound/PageNotFound';
import Modal from './Components/Modal/Modal';

function App() {
  const showModal = useSelector((state) => state.modal.showModal);
  const activeCourse = useSelector((state) => state.modal.activeCourse);

  // Get the dispatch function to dispatch actions
  const dispatch = useDispatch();
  const handleCloseModal = () => dispatch(closeModal());

  return (
    <Router>
      <div>
        <Modal
          showModal={showModal}
          activeCourse={activeCourse}
          closeModal={handleCloseModal}
        />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/courses" element={<CoursePage />} />
          <Route path="/courseDetails" element={<CourseLandingPage />} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/blogPost" element={<BlogPost />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/cancellationPolicy" element={<CancellationPolicy />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/CookiePolicy" element={<CookiePolicy />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
