import React from 'react';
import './ServicesPage.css';

const ServicesPage = () => {
  return (
    <div className="services-page">
        <h1 className="page-title">Our Services</h1>
      <div className="services-content">
        <section className="service-section">
          <h2>Training Programs</h2>
          <p>
            RuhiTech Solutions offers comprehensive training programs in coding
            and software testing. Our curriculum covers a wide range of topics,
            from programming languages to advanced testing methodologies. We
            equip individuals with the essential skills needed to excel in
            various software technologies.
          </p>
        </section>
        <section className="service-section">
          <h2>Job Consulting Services</h2>
          <p>
            We guide students through job placements and career development. Our
            expert team provides personalized support, ensuring that our
            students are well-prepared for the job market.
          </p>
        </section>
        <section className="service-section">
          <h2>Focus on Practical Skills</h2>
          <p>
            With a strong focus on practical skills and industry relevance,
            RuhiTech Solutions bridges the gap between education and employment,
            preparing students for real-world challenges.
          </p>
        </section>
        <section className="service-section">
          <h2>Future Plan</h2>
          <h3>Expanding Our Horizons</h3>
          <p>
            Looking ahead, RuhiTech Solutions plans to expand by acquiring IT
            projects and applying our expertise to real-world scenarios. This
            strategic move aims to enhance our service offerings and deepen our
            impact in the industry.
          </p>
        </section>
      </div>
    </div>
  );
};

export default ServicesPage;
