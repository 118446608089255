import React from 'react';
import './TrainingVideo.css';
import JoinUsImage from '../../../Assets/Images/joinus.png';
import RuhiTechVideo from '../../../Assets/Video/ruhitech.mov';

const TrainingVideo = () => {
  return (
    <div className="training-video-container">
      <div className="content-wrapper">
        <div className="image-wrapper">
          <img src={JoinUsImage} alt="Training" />
        </div>
        <div className="video-wrapper">
          <h2 className="page-title">Get to Know RuhiTech Solutions</h2>
          <video className="training-video" controls>
            <source src={RuhiTechVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default TrainingVideo;
