import React from 'react';
import './FeedbackSection.css';

const FeedbackSection = ({ feedbacks }) => {

    if(!feedbacks || feedbacks.length === 0 ) {
        return null;
    }
  return (
    <div className="feedback-section">
      <h2 className="page-title">User Feedback</h2>

      <div className="feedback-list">
        {feedbacks.map((feedback, index) => (
          <div key={index} className="feedback-item">
            <p className="feedback-text">"{feedback.text}"</p>
            <span className="feedback-author">- {feedback.author}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeedbackSection;
