import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import BlogPage from './BlogsPage';
import PageBanner from '../PageBanner/PageBanner';
import BlogsImage from '../../Assets/Images/blogs.jpg';

const BlogsPage = () => {
  return (
    <>
      <Header />
      <PageBanner
        title={'Insights and Updates'}
        description={
          'Stay informed with our latest articles and expert perspectives'
        }
        image={BlogsImage}
      />
      <BlogPage />
      <Footer />
    </>
  );
};

export default BlogsPage;
