import React from 'react';
import './CookiePolicy.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

const CookiePolicy = () => {
  return (
    <>
      <Header />
      <div className="rtc-cookie-container">
        <h1 className="page-title">Cookie Policy</h1>
        <p>
          Effective Date:{' '}
          <span className="policy-update-date">06-sep-2024</span>
        </p>
        <section className="rtc-cookie-section">
          <h2 className="rtc-cookie-heading">Introduction</h2>
          <p className="rtc-cookie-text">
            This Cookie Policy explains how RuhiTech Solutions uses cookies and
            similar technologies to recognize you when you visit our website. It
            explains what these technologies are, why we use them, and your
            rights to control their use.
          </p>
        </section>

        <section className="rtc-cookie-section">
          <h2 className="rtc-cookie-heading">What Are Cookies?</h2>
          <p className="rtc-cookie-text">
            Cookies are small data files placed on your device when you visit a
            website. They are used to remember your preferences, enhance your
            browsing experience, and provide us with information about how you
            use our website.
          </p>
        </section>

        <section className="rtc-cookie-section">
          <h2 className="rtc-cookie-heading">Why We Use Cookies</h2>
          <p className="rtc-cookie-text">
            We use cookies to:
            <ul>
              <li>
                Enhance your browsing experience by remembering your preferences
                and settings.
              </li>
              <li>
                Understand how you use our website to improve our services and
                functionality.
              </li>
              <li>
                Provide personalized content and advertisements based on your
                interests.
              </li>
              <li>
                Analyze website traffic and performance to make data-driven
                decisions.
              </li>
            </ul>
          </p>
        </section>

        <section className="rtc-cookie-section">
          <h2 className="rtc-cookie-heading">Types of Cookies We Use</h2>
          <p className="rtc-cookie-text">
            We use the following types of cookies:
            <ul>
              <li>
                <strong>Essential Cookies:</strong> Necessary for the website to
                function properly. They enable basic features like page
                navigation and secure access to certain areas.
              </li>
              <li>
                <strong>Performance Cookies:</strong> Collect information on how
                visitors use our website to improve its performance and enhance
                user experience.
              </li>
              <li>
                <strong>Functional Cookies:</strong> Allow the website to
                remember your choices and preferences, providing a more
                personalized experience.
              </li>
              <li>
                <strong>Advertising Cookies:</strong> Used to deliver relevant
                advertisements to you and track the effectiveness of our
                marketing campaigns.
              </li>
            </ul>
          </p>
        </section>

        <section className="rtc-cookie-section">
          <h2 className="rtc-cookie-heading">How to Manage Cookies</h2>
          <p className="rtc-cookie-text">
            You can control and manage cookies in various ways. However, please
            note that removing or blocking cookies may impact your user
            experience and parts of our website may no longer be fully
            accessible.
            <br />
            <br />
            <strong>Browser Settings:</strong> Most web browsers allow you to
            manage cookie settings through their settings or preferences menu.
            You can set your browser to refuse cookies or delete existing
            cookies.
            <br />
            <br />
            <strong>Opt-Out Links:</strong> Some third-party cookies can be
            managed through opt-out links provided by the respective advertisers
            or third-party providers.
          </p>
        </section>

        <section className="rtc-cookie-section">
          <h2 className="rtc-cookie-heading">Changes to This Cookie Policy</h2>
          <p className="rtc-cookie-text">
            RuhiTech Solutions reserves the right to update this Cookie Policy
            from time to time. We will notify you of any changes by posting the
            new policy on our website. Your continued use of our website after
            any changes constitutes your acceptance of the updated policy.
          </p>
        </section>

        <section className="rtc-cookie-section">
          <h2 className="rtc-cookie-heading">Contact Us</h2>
          <p className="rtc-cookie-text">
            If you have any questions or concerns about our Cookie Policy,
            please contact us at:
            <br />
            Email: contact@ruhitechsolutions.com
            <br />
            Phone: +91-8220343006
            <br />
            Address: 89/15, 1st Main Road, Gandhi Nagar, Adyar, Chennai-600020
          </p>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default CookiePolicy;
