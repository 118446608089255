import React from 'react';
import {
  FaStar,
  FaChalkboardTeacher,
  FaProjectDiagram,
  FaUserShield,
} from 'react-icons/fa';
import './ChooseUS.css';

const reasons = [
  {
    icon: <FaChalkboardTeacher />,
    title: 'Expert Instructors',
    description:
      'Learn from industry professionals with years of real-world experience.',
  },
  {
    icon: <FaProjectDiagram />,
    title: 'Hands-on Projects',
    description:
      'Work on live projects to gain practical knowledge and skills.',
  },
  {
    icon: <FaUserShield />,
    title: 'Personalized Attention',
    description: 'Small class sizes ensure personalized feedback and support.',
  },
  {
    icon: <FaStar />,
    title: 'Certification',
    description:
      'Receive a recognized certification upon successful completion of your course.',
  },
];

const ChooseUS = () => {
  return (
    <div className="why-choose-us">
      <h2 className="page-title">Our Unique Advantages</h2>
      <div className="reasons-container">
        {reasons.map((reason, index) => (
          <div className="reason-card" key={index}>
            <div className="icon-container">{reason.icon}</div>
            <h3 className="reason-title">{reason.title}</h3>
            <p className="reason-description">{reason.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChooseUS;
