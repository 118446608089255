import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './CourseSyllabus.css';

// Helper function to calculate total time for a specific syllabus section
const calculateTotalTime = (topics) => {
  let totalHours = 0;
  let totalMinutes = 0;

  topics?.forEach((topic) => {
    totalHours += topic.hours;
    totalMinutes += topic.minutes;
  });

  // Convert minutes to hours
  totalHours += Math.floor(totalMinutes / 60);
  totalMinutes = totalMinutes % 60;

  return { totalHours, totalMinutes };
};

const formatTime = (hours, minutes) => {
  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ${minutes > 0 ? `${minutes} minute${minutes > 1 ? 's' : ''}` : ''}`;
  } else {
    return `${minutes} minute${minutes > 1 ? 's' : ''}`;
  }
};

const CourseSyllabus = ({ syllabusTopics, selectedCourse }) => {
  const selectedTechnologies = selectedCourse.technologies;
  const [expanded, setExpanded] = useState(selectedTechnologies[0]); // Initially expanded first accordion

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <h2 className="page-title">Course Content</h2>
      {selectedTechnologies.map((category, index) => {
        const topics = syllabusTopics[category].syllabus;
        if (!topics) return null;

        const { totalHours, totalMinutes } = calculateTotalTime(topics);

        return (
          <div className="syllabus-container" key={category}>
            <Accordion
              expanded={expanded === category} // Expand if this is the currently expanded panel
              onChange={handleChange(category)} // Control which accordion is expanded
              className="syllabus-accordion"
              sx={{
                backgroundColor: '#eaf2f8', // Apply pastel color based on index
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${category}-content`}
                id={`${category}-header`}
                className="syllabus-summary"
              >
                <Typography className="syllabus-header-text">
                  {syllabusTopics[category].title} – Total Time: {formatTime(totalHours, totalMinutes)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="syllabus-details">
                <ul className="syllabus-list">
                  {topics.map((topic, index) => (
                    <li key={index} className="syllabus-list-item">
                      <Typography variant="body1" className="syllabus-name">
                        <strong>{topic.title}</strong>
                      </Typography>
                      <Typography className="syllabus-time">
                        {formatTime(topic.hours, topic.minutes)}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
};

export default CourseSyllabus;
