import React from 'react';
import './ImportanceComponent.css';

const ImportanceComponent = ({ title, points }) => {
  return (
    <div className="importance-section">
      <h2 className="page-title">{title}</h2>
      <div className="importance-list">
        {points.map((point, index) => (
          <div key={index} className="importance-item">
            <div className="importance-icon">{point.icon}</div>
            <div className="importance-content">
              <h3>{point.title}</h3>
              <p>{point.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImportanceComponent;
