import {
  FaCheckCircle,
} from 'react-icons/fa';
import './SuccessMessage.css'

const SuccessMessage = ({ title, message }) => {
    return (
      <div className="success-message-container">
        <div className="success-message-icon">
          <FaCheckCircle size={50} color="#4CAF50" />
        </div>
        <div className="success-message-text">
          <h2>{title || ''}</h2>
          <p>{message || ''}</p>
        </div>
      </div>
    );
  };

  export default SuccessMessage