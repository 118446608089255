import React from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from '../../reducers/modalSlice';
import './PageBanner.css';

const PageBanner = ({ title, description, image, buttonText }) => {
  const dispatch = useDispatch();
  return (
    <div
      className="pagebanner-course-banner"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="pagebanner-overlay"></div>
      <div className="pagebanner-course-content">
        <h1 className="pagebanner-course-title">{title}</h1>
        <p className="pagebanner-course-description">{description}</p>
        {buttonText && (
          <button
            className="pagebanner-enroll-button"
            onClick={() => dispatch(openModal(title))}
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default PageBanner;
