// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getDatabase } from 'firebase/database';
import { getFunctions, httpsCallable } from 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyALzdlYSZYu68bx_wqDraeTjy_p1OWVEIk",
  authDomain: "ruhitech-solutions-b0c3d.firebaseapp.com",
  databaseURL: "https://ruhitech-solutions-b0c3d-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ruhitech-solutions-b0c3d",
  storageBucket: "ruhitech-solutions-b0c3d.appspot.com",
  messagingSenderId: "912776634989",
  appId: "1:912776634989:web:efb4c3752b88b698661564",
  measurementId: "G-92TCG0084S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize the database
const database = getDatabase(app);
const fb_function = getFunctions(app);
const analytics = getAnalytics(app);

export { app, database, fb_function, analytics, httpsCallable };
