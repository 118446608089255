import React from 'react';
import { useDispatch } from 'react-redux';
import './InfoBar.css';
import { openModal } from '../../reducers/modalSlice';

const InfoBar = () => {
  const dispatch = useDispatch();

  return (
    <div className="info-bar">
      <p className="announcement">
        <span onClick={() => dispatch(openModal('Frontend Master'))}>
          Click here to join our Training program!
        </span>{' '}
      </p>
    </div>
  );
};

export default InfoBar;
