import React from 'react';
import './Banner.css';
import bannerImage from '../../../Assets/Images/banner.jpg';

const Banner = () => {
  return (
    <div
      className="homepagebanner"
      style={{ backgroundImage: `url(${bannerImage})` }}
    >
      <div className="homepagebanner-content">
        <h1 className="homepagebanner-title">
          Start Your Career & Pursue Your Passion
        </h1>
        <h3 className="homepagebanner-subtitle">
          Transform your future with our training programs
        </h3>
        <div className="homepagebanner-buttons">
          <a
            className="homepagebanner-btn homepagebanner-btn-primary"
            href="/courses"
          >
            View Our Courses
          </a>
          <a
            className="homepagebanner-btn homepagebanner-btn-secondary"
            href="/contact"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default Banner;
