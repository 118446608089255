import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import CourseList from '../HomePage/CourseList/CourseList';

const CourseLanding = () => {
  return (
    <>
      <Header />
      <CourseList showAllCourses={true} />
      <Footer />
    </>
  );
};

export default CourseLanding;
