import React, { useEffect, useState } from 'react';
import './Modal.css';
import { postEnrollmentData } from '../../Services/enrollFormService'; // Import your service for form submission
import { postRecaptchaDetails } from '../../Services/recaptchaService';
import { FaExclamationCircle } from 'react-icons/fa';
import ReCAPTCHA from 'react-google-recaptcha';
import SuccessMessage from '../SuccessMessage/SuccessMessage';
import {syllabusTopics} from '../CoursePage/courseModel'

const Modal = ({ showModal, activeCourse, closeModal }) => {
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedBatchType, setSelectedBatchType] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const courseModalData = Object.values(syllabusTopics).map(course => course.showCourse && course.title).filter(Boolean)
  console.log(courseModalData)
  const availableBatch = ['Weekdays', 'Weekends']

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden'; // Disable scroll
    } else {
      document.body.style.overflow = ''; // Restore scroll
    }

    // Clean up to restore scroll when component unmounts or modal closes
    return () => {
      document.body.style.overflow = '';
    };
  }, [showModal]);

  useEffect(() => {
    if (showModal) {
      setErrors({});
      setSuccessMessage('');
      setName('');
      setEmail('');
      setPhone('');
      setSelectedCourse(activeCourse);
      setSelectedBatchType('');
    }
  }, [showModal, activeCourse]);

  const setFormError = () => {
    setErrors({
      form: 'Failed to submit your enrollment. Please try again later.',
    });
  };
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const fieldErrors = errors

    // Update state for the input
    switch (name) {
      case 'name':
        const nameRegex = /^[A-Za-z]+$/;
        if (!nameRegex.test(value)) {
          fieldErrors.name = 'Name should contain only alphabets';
        } else {
          fieldErrors.name = '';
        }
        if(value.length <= 30) {
          setName(value);
        }
        break;
      case 'email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          fieldErrors.email = 'Please enter a valid email address';
        } else {
          fieldErrors.email = '';
        }
        if(value.length <= 30) {
          setEmail(value);
        }
        break;
      case 'phone':
        const phoneRegex = /^[0-9]+$/; // Allows one or more digits
        if (!phoneRegex.test(value)) {
          fieldErrors.phone = 'Phone number should contain only numbers';
        } else {
          fieldErrors.phone = '';
        }
        if(value.length <= 10) {
          setPhone(value);
        }
        break;
      case 'course':
        setSelectedCourse(value);
        break;
      case 'batchType':
        setSelectedBatchType(value);
        break;
      default:
        break;
    }

    setErrors(fieldErrors);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
      try {
        const userDetails = {
          name,
          email,
          phone,
          course: selectedCourse,
          batchType: selectedBatchType
        };
        const response = await postEnrollmentData(userDetails);
        if (response.status === 200) {
          setSuccessMessage('Your enrollment has been submitted successfully.');
        } else {
          setFormError();
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        setFormError();
      } finally {
        setLoading(false);
      }
    }

  const handleRecaptchaSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      alert('Please complete the reCAPTCHA');
      return;
    }

    setLoading(true);
    try {
      const captchaResponse = await postRecaptchaDetails(recaptchaValue);
      if (captchaResponse.status === 200) {
        handleFormSubmit(e);
      } else {
        setFormError();
      }
    } catch (error) {
      setFormError();
      console.error('Error with reCAPTCHA:', error);
    }
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const isValid = (formErrors) => {
    // Check if all values in validationErrors are empty strings
    return Object.values(formErrors).every(value => value === '');
  };

  // Function to check if the form is valid
  const isFormValid = () => {
    const allFieldsFilled = !!(name && email && phone && selectedCourse && selectedBatchType && recaptchaValue);

    return allFieldsFilled && isValid(errors)
  };

  if (!showModal) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button onClick={closeModal} className="modal-close-button">X</button>
        <h2 className="modal-heading">Enroll Now</h2>
        {loading && <div className="loader"></div>}
        {successMessage && <SuccessMessage title="Thank you for enrolling!" message="Your request has been received successfully. We will get back to you shortly." />}
        {!successMessage && !loading && (
          <form onSubmit={handleRecaptchaSubmit} className="modal-form">
            <div className="modal-form-group">
              <label htmlFor="name">Name: <span className="mandatory">*</span></label>
              <input type="text" name="name" id="name" value={name} onChange={handleInputChange} placeholder="Your name" required />
              {errors.name && <span className="error-message">{errors.name}</span>}
            </div>
            <div className="modal-form-group">
              <label htmlFor="phone">Phone Number(+91): <span className="mandatory">*</span></label>
              <input type="tel" name="phone" id="phone" max="10" value={phone} onChange={handleInputChange} placeholder="10 digit phone number" required />
              {errors.phone && <span className="error-message">{errors.phone}</span>}
            </div>
            <div className="modal-form-group">
              <label htmlFor="email">Email: <span className="mandatory">*</span></label>
              <input type="email" name="email" id="email" value={email} onChange={handleInputChange} placeholder="Email address" required />
              {errors.email && <span className="error-message">{errors.email}</span>}
            </div>
            <div className="modal-form-group">
              <label htmlFor="course">Course: <span className="mandatory">*</span></label>
              <select name="course" id="course" value={selectedCourse} onChange={handleInputChange} required>
                <option value="">Select a Course</option>
                {courseModalData.map((course) => (
                  <option key={course} value={course}>{course}</option>
                ))}
              </select>
            </div>
            {selectedCourse && (
              <div className="modal-form-group">
                <label htmlFor="batchType">Available Batch: <span className="mandatory">*</span></label>
                <select name="batchType" id="batchType" value={selectedBatchType} onChange={handleInputChange} required>
                  <option value="">Select a Batch Type</option>
                  {availableBatch.map((batch) => (
                    <option key={batch} value={batch}>{batch}</option>
                  ))}
                </select>
              </div>
            )}
            <div className="recaptchaContainer">
              <ReCAPTCHA sitekey="6Lf8-TwqAAAAAIzC__WcL3VnfWR4A2B6PYFS0pve" onChange={handleRecaptchaChange} />
            </div>
            <button type="submit" className="modal-submit-button" disabled={!isFormValid()}>
              Submit
            </button>
            {errors.form && (
              <div className="enroll-error-container">
                <FaExclamationCircle className="enroll-error-icon" />
                <p className="enroll-error-message">{errors.form}</p>
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default Modal;


