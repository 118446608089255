import React from 'react';
import './Footer.css';
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h2 className="footer-title">Contact Us</h2>
          <p className="footer-text">
            <FaPhoneAlt /> +91-8220343006
          </p>
          <p className="footer-text">
            <FaEnvelope /> contact@ruhitechsolutions.com
          </p>
          <p className="footer-text">
            <FaMapMarkerAlt /> 89/15 (Basement Floor), 1st Main Road, Gandhi
            Nagar, Adyar, Chennai-600020
          </p>
        </div>
        <div className="footer-section quick-links">
          <h2 className="footer-title">Quick Links</h2>
          <ul className="footer-links">
            <li>
              <a href="/" className="footer-link">
                Home
              </a>
            </li>
            <li>
              <a href="/courses" className="footer-link">
                Courses
              </a>
            </li>
            <li>
              <a href="/services" className="footer-link">
                Services
              </a>
            </li>
            <li>
              <a href="/blogs" className="footer-link">
                Blogs
              </a>
            </li>
            <li>
              <a href="/contact" className="footer-link">
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-section quick-links">
          <h2 className="footer-title">Useful Links</h2>
          <ul className="footer-links">
            <li>
              <a href="/cancellationPolicy" className="footer-link">
                Cancellation/Refund Policy
              </a>
            </li>
            <li>
              <a href="/TermsAndConditions" className="footer-link">
                Terms and Conditions
              </a>
            </li>
            <li>
              <a href="/PrivacyPolicy" className="footer-link">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="/CookiePolicy" className="footer-link">
                Cookie Policy
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-section social-media">
          <h2 className="footer-title">Follow Us</h2>
          <div className="social-icons">
            <a
              href="https://www.facebook.com/profile.php?id=61565261795993"
              className="social-icon"
              aria-label="Facebook"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://www.youtube.com/@RuhitechSolutions"
              className="social-icon"
              aria-label="YouTube"
            >
              <FaYoutube />
            </a>
            <a
              href="https://x.com/ruhitechsols"
              className="social-icon"
              aria-label="Twitter"
            >
              <FaTwitter />
            </a>
            {/* <a href="https://linkedin.com" className="social-icon" aria-label="LinkedIn"><FaLinkedinIn /></a> */}
            <a
              href="https://www.instagram.com/ruhitechsolutions?utm_source=qr&igsh=cTcwZTRjdWV3OXE="
              className="social-icon"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          &copy; {new Date().getFullYear()} Ruhi Tech Solutions. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
