import React from 'react';
import './CompanyLogos.css';

const CompanyLogos = ({ companies }) => {
  return (
    <div className="company-logos-section">
      <h2 className="page-title">Companies Hiring React Developers</h2>
      <div className="company-logos-grid">
        {companies.map((company, index) => (
          <div key={index} className="company-logo-item">
            <img src={company.logo} alt={`${company.name} logo`} className="company-logo" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanyLogos;
