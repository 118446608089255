import React, { useState, useEffect } from 'react';
import './ContactUs.css';
import {
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaWhatsapp,
  FaExclamationCircle,
} from 'react-icons/fa';
import { postContactDetailsData } from '../../../Services/contactFormService';
import { postRecaptchaDetails } from '../../../Services/recaptchaService';
import ReCAPTCHA from 'react-google-recaptcha';
import SuccessMessage from '../../SuccessMessage/SuccessMessage';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    phone: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  useEffect(() => {
    // Check if all fields are filled out to enable the button
    const isFormValid = Object.values(formData).every(value => value.trim() !== '');
    setIsButtonEnabled(isFormValid);
  }, [formData]); // Dependency array includes formData

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = (data) => {
    const errors = {};
    const { phone, email, message } = data;

    // Phone number validation
    const phoneRegex = /^[0-9]{10,13}$/;
    if (!phoneRegex.test(phone)) {
      errors.phone =
        'Phone number should be 10 to 13 digits and contain only numbers';
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      errors.email = 'Please enter a valid email address';
    }

    // Message validation
    if (message.trim().length === 0) {
      errors.message = 'Message cannot be empty';
    }

    return errors;
  };

  const setFormError = () => {
    setErrors({
      form: 'Failed to send your message. Please try again later.',
    });
  }

  const handleRecaptchaSubmit = async (e) => {
    e.preventDefault();
  
    if (!recaptchaValue) {
      alert("Please complete the reCAPTCHA");
      return;
    }
    // Set loading to true
    setLoading(true);
    try {
      const captchaResponse = await postRecaptchaDetails(recaptchaValue);
      if(captchaResponse.status === 200) {
        handleSubmit()
      } else{
        setFormError()
      } 
    } catch (error) {
      setFormError()
      console.error('Error with reCAPTCHA:', error);
    }
  };

  const handleSubmit = async () => {
    // Validate form data
    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      // Simulate form submission
      const response = await postContactDetailsData(formData);

      if (response.status === 200) {
        setFormSubmitted(true);
      } else {
        setFormError()
      }

      setFormData({
        phone: '',
        email: '',
        message: '',
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrors({
        form: 'An error occurred while sending your message. Please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const whatsappLink = 'https://wa.me/918220343006';

  return (
    <div className="contact-us-section">
      <div className="contact-info">
        <h2 className="page-title">Get in Touch</h2>
        <div className="contact-details">
          <div className="contact-item">
            <FaPhoneAlt className="contact-icon" />
            <span className="contact-text">+91-8220343006</span>
          </div>
          <div className="contact-item">
            <FaEnvelope className="contact-icon" />
            <span className="contact-text">contact@ruhitechsolutions.com</span>
          </div>
          <div className="contact-item">
            <FaMapMarkerAlt className="contact-icon" />
            <span className="contact-text">
              89/15, 1st Main road, Gandhi nagar, Adyar, Chennai-600020
            </span>
          </div>
          <a href={whatsappLink} className="btn btn-whatsapp">
            <FaWhatsapp className="whatsapp-icon" /> Chat on WhatsApp
          </a>
        </div>
      </div>
      <div className="contact-form">
        {loading ? (
          <div className="loader"></div>
        ) : formSubmitted ? (
          <SuccessMessage title='Thank you for reaching out!' message='Your request has been received successfully. We will get back to you shortly.'/>
        ) : (
          <>
            <h2 className="page-title">Send Us a Message</h2>
            <form onSubmit={handleRecaptchaSubmit}>
              <div className="form-group">
                <label htmlFor="phone">
                  Phone Number <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Your phone number"
                />
                {errors.phone && (
                  <p className="error-text">
                    <FaExclamationCircle className="error-icon" />{' '}
                    {errors.phone}
                  </p>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="email">
                  Email <span className="mandatory">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Your email"
                />
                {errors.email && (
                  <p className="error-text">
                    <FaExclamationCircle className="error-icon" />{' '}
                    {errors.email}
                  </p>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="message">
                  Message <span className="mandatory">*</span>
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Your message"
                ></textarea>
                {errors.message && (
                  <p className="error-text">
                    <FaExclamationCircle className="error-icon" />{' '}
                    {errors.message}
                  </p>
                )}
              </div>
              <div className='recaptchaContainer'>
                <ReCAPTCHA
                  sitekey="6Lf8-TwqAAAAAIzC__WcL3VnfWR4A2B6PYFS0pve"
                  onChange={handleRecaptchaChange}
                />
              </div>
              <button
                type="submit"
                className="btn contact-btn-submit"
                disabled={!isButtonEnabled || !recaptchaValue}
              >
                Send Message
              </button>
              {errors.form && (
                <div className="enroll-error-container">
                  <FaExclamationCircle className="enroll-error-icon" />
                  <p className="enroll-error-message">{errors.form}</p>
                </div>
              )}
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ContactUs;
