import React, { useState } from 'react';
import './CourseFAQs.css';
import { FaPlus, FaMinus } from 'react-icons/fa';

const faqs = [
  {
    question: 'What is the duration of the courses?',
    answer:
      'Each course varies in duration, but most range between 25 to 80 hours, depending on the content and level of depth.',
  },
  {
    question: 'Do you provide certificates after completion?',
    answer:
      'Yes, all our courses come with a certificate of completion, which can help you in your professional career.',
  },
  {
    question: 'Are there any prerequisites for the courses?',
    answer:
      'Some advanced courses may require prior knowledge, but our foundational courses are designed for beginners.',
  },
  {
    question: 'Can I enroll in more than one course at a time?',
    answer:
      'Yes, you can enroll in multiple courses simultaneously if you have the time and dedication to manage them.',
  },
  {
    question: 'What if I miss a class?',
    answer:
      'If you miss a class, no worries! We offer make-up classes after regular hours for up to three missed sessions. This ensures you can catch up on the material and stay on track with the course.',
  },
  {
    question: 'What if I need to take continuous leave?',
    answer:
      'You are allowed to take up to three days of continuous leave. If your leave exceeds three continuous days, you will be moved to the next batch to ensure you don’t fall behind. Please inform us in advance if you anticipate extended leave.',
  },
  {
    question: 'What does your placement assistance include?',
    answer:
      'We provide comprehensive placement assistance, including resume building, interview preparation, and job search support. While we offer these resources and guidance, please note that we cannot guarantee placement. However, we focus on equipping candidates with the necessary skills to enhance their job prospects.',
  },
  {
    question: 'What is your refund policy?',
    answer: (
      <span>
        For details on our refund policy, please refer to our{' '}
        <a href="/cancellationPolicy">Refund Policy</a>. This link will provide
        you with all the information you need regarding eligibility and the
        process.
      </span>
    ),
  },
];

const CourseFAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faqs-section">
      <h2 className="page-title">Course FAQs</h2>
      <div className="faqs-container">
        {faqs.map((faq, index) => (
          <div
            className={`faq-item ${openIndex === index ? 'open' : ''}`}
            key={index}
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq-question">
              {faq.question}
              <span className="faq-icon">
                {openIndex === index ? <FaMinus /> : <FaPlus />}
              </span>
            </div>
            {openIndex === index && (
              <div className="faq-answer">{faq.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseFAQs;
