import React from 'react';
import './CourseOutline.css';

const CourseOutline = ({ skills }) => {
  return (
    <div className="learn-section">
      <h2 className="page-title">What You Will Learn</h2>
      <div className="learn-content">
        {skills.map((skill, index) => (
          <div key={index} className="learn-card">
            <h3 className="card-title">{skill}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseOutline;
