import React from 'react';
import './TermsAndConditions.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

const TermsAndConditions = () => {
  return (
    <>
      <Header />
      <div className="rtc-terms-container">
        <h1 className="page-title">Terms and Conditions</h1>
        <p>
          Effective Date:{' '}
          <span className="policy-update-date">06-sep-2024</span>
        </p>

        <section className="rtc-section">
          <h2 className="rtc-heading">1. Dignity and Respect in Class</h2>
          <p className="rtc-text">
            All students are expected to maintain a high standard of dignity and
            respect during classes. Discrimination, harassment, or any form of
            disrespect towards instructors or fellow students will not be
            tolerated. Any violations may result in immediate termination of the
            course without a refund.
          </p>
        </section>

        <section className="rtc-section">
          <h2 className="rtc-heading">2. Behavioral Expectations</h2>
          <p className="rtc-text">
            Students must behave in a professional and courteous manner at all
            times. Disruptive behavior, including but not limited to
            inappropriate language, aggressive conduct, or any actions that
            hinder the learning environment, will lead to disciplinary action,
            which may include suspension or expulsion from the course without a
            refund.
          </p>
        </section>

        <section className="rtc-section">
          <h2 className="rtc-heading">3. Attendance and Punctuality</h2>
          <p className="rtc-text">
            Students are expected to attend classes on time. Arriving late
            disrupts the learning environment and shows a lack of respect for
            both the instructor and fellow students. Continuous tardiness or
            absence without prior notification may result in disciplinary
            actions, including potential dismissal from the course.
          </p>
        </section>

        <section className="rtc-section">
          <h2 className="rtc-heading">4. Missed Classes and Rescheduling</h2>
          <p className="rtc-text">
            In the event a student misses a class, they are entitled to one
            rescheduled session. Requests for rescheduling must be made within
            24 hours of the missed class. If a student misses the rescheduled
            class or fails to inform in advance, their enrollment may be
            terminated without a refund.
          </p>
        </section>

        <section className="rtc-section">
          <h2 className="rtc-heading">5. Website Usage</h2>
          <p className="rtc-text">
            By accessing or using the RuhiTech Solutions website, you agree to
            comply with and be bound by these terms. Unauthorized use of the
            website, including but not limited to hacking, data mining, or any
            other activity that compromises the security or functionality of the
            website, is strictly prohibited and will be prosecuted to the
            fullest extent of the law.
          </p>
        </section>

        <section className="rtc-section">
          <h2 className="rtc-heading">6. Usage of Personal Information</h2>
          <p className="rtc-text">
            RuhiTech Solutions is committed to protecting the privacy of our
            students. Any personal information collected during the course
            registration process will be used solely for educational and
            administrative purposes. We will not share, sell, or distribute your
            personal information without your explicit consent, except as
            required by law.
          </p>
        </section>

        <section className="rtc-section">
          <h2 className="rtc-heading">7. Intellectual Property</h2>
          <p className="rtc-text">
            All content, materials, and resources provided during the course are
            the intellectual property of RuhiTech Solutions and are intended for
            personal educational use only. Unauthorized reproduction,
            distribution, or use of these materials is prohibited and may result
            in legal action.
          </p>
        </section>

        <section className="rtc-section">
          <h2 className="rtc-heading">8. Limitation of Liability</h2>
          <p className="rtc-text">
            RuhiTech Solutions shall not be held liable for any direct,
            indirect, incidental, or consequential damages arising out of or in
            connection with the course or the use of our website. This includes,
            but is not limited to, any loss of data, revenue, or profit.
          </p>
        </section>

        <section className="rtc-section">
          <h2 className="rtc-heading">9. Termination Terms</h2>
          <p className="rtc-text">
            RuhiTech Solutions reserves the right to terminate a student's
            course registration under circumstances that include, but are not
            limited to, non-compliance with these terms and conditions,
            disruptive behavior, or failure to meet financial obligations.
            Termination may be immediate and without refund.
          </p>
        </section>

        <section className="rtc-section">
          <h2 className="rtc-heading">10. Changes to Terms and Conditions</h2>
          <p className="rtc-text">
            RuhiTech Solutions reserves the right to amend these terms and
            conditions at any time without prior notice. Students are encouraged
            to review the terms and conditions regularly to stay informed of any
            updates.
          </p>
        </section>

        <section className="rtc-section">
          <h2 className="rtc-heading">11. Governing Law</h2>
          <p className="rtc-text">
            These terms and conditions shall be governed by and construed in
            accordance with the laws of India. Any disputes arising from these
            terms and conditions shall be subject to the exclusive jurisdiction
            of the courts in Chennai, Tamil Nadu.
          </p>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
