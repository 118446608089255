import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import InfoBar from '../Infobar/InfoBar';
import logo from '../../Assets/Images/logo.png';
import './Header.css';
import { openModal } from '../../reducers/modalSlice';

const Header = () => {
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [navbarExpanded, setNavbarExpanded] = useState(false);

  const dispatch = useDispatch();
  const handleOpenModal = (activeCourse) => {
    dispatch(openModal(activeCourse));
    setNavbarExpanded(false);
  };

  const controlHeader = () => {
    if (window.scrollY > lastScrollY) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', controlHeader);
    return () => {
      window.removeEventListener('scroll', controlHeader);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScrollY]);


  return (
    <div className="header-container">
      <InfoBar />
      <Navbar
        expand="lg"
        className={`custom-navbar ${showHeader ? 'visible' : 'hidden'}`}
        fixed="top"
        expanded={navbarExpanded}
        onToggle={(expanded) => setNavbarExpanded(expanded)}
      >
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              width="70"
              height="30"
              className="d-inline-block align-top"
              alt="Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                as={Link}
                to="/"
                onClick={() => setNavbarExpanded(false)}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/courses"
                onClick={() => setNavbarExpanded(false)}
              >
                Courses
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/services"
                onClick={() => setNavbarExpanded(false)}
              >
                Services
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/blogs"
                onClick={() => setNavbarExpanded(false)}
              >
                Blogs
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact"
                onClick={() => setNavbarExpanded(false)}
              >
                Contact
              </Nav.Link>
              <Button
                variant="outline-dark"
                className="enroll-button"
                onClick={() => handleOpenModal('Frontend Master')}
              >
                Enroll Now
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
