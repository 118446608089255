import React from 'react';
import './CourseInfoSection.css';

const CourseInfoSection = ({courseInfo}) => {
  return (
    <div className="skills-section">
      {courseInfo.map((skill, index) => (
        <div className="skill-item" key={index}>
          <i className={skill.iconClass}></i>
          <span>{skill.label}</span>
        </div>
      ))}
    </div>
  );
};

export default CourseInfoSection;
