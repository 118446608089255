import { httpsCallable, fb_function } from '../firebaseConfig';
const enrollCandidate = httpsCallable(fb_function, 'enrollCandidate');

export const postEnrollmentData = async (enrollmentDetails) => {
  try {
    console.log("passing", enrollmentDetails)
    const response = await enrollCandidate(enrollmentDetails);
    return response.data;
  } catch (error) {
    console.error('Error enrolling customer:', error);
    return error;
  }
};
