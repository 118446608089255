import { httpsCallable, fb_function } from '../firebaseConfig';
const contactUs = httpsCallable(fb_function, 'contactUs');

export const postContactDetailsData = async (contactDetails) => {
  try {
    const response = await contactUs(contactDetails);
    return response.data;
  } catch (error) {
    console.error('Error enrolling customer:', error);
    return error;
  }
};
