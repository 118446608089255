import React from 'react';
import './Blogs.css';
import { blogs } from '../../../ModelData/ModelData';

const Blogs = () => {
  return (
    <div className="blogs-section">
      <h2 className="page-title">Latest Blogs</h2>
      <div className="blogs-container">
        {blogs.map((blog, index) => (
          <div className="blog-card" key={index}>
            <img
              src={blog.imageUrl || 'https://via.placeholder.com/360x200'}
              alt={blog.title}
              className="blog-image"
            />
            <h3 className="blog-title">{blog.title}</h3>
            <p className="blog-description">{blog.description}</p>
            <div className="blog-footer">
              <span className="blog-date">{blog.date}</span>
              <a href={`/blogPost?blogId=${blog.blogId}`} className="blog-link">
                Read More
              </a>
            </div>
          </div>
        ))}
      </div>
      <div className="explore-button-container">
        <a href="/blogs">
          <button className="btn btn-explore-more">Explore More Blogs</button>
        </a>
      </div>
    </div>
  );
};

export default Blogs;
