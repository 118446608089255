import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import './BlogPost.css'; // Import the CSS for styling
import { blogs } from '../../../ModelData/ModelData';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

const BlogPost = () => {
  const [searchParams] = useSearchParams();
  const [blogDetails, setBlogDetails] = useState({});

  const currentBlogId = searchParams.get('blogId');
  const currentBlog = blogs.find((blogVal) => currentBlogId === blogVal.blogId);

  useEffect(() => {
    if (currentBlog) {
      setBlogDetails(currentBlog);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBlog]);

  const { content } = blogDetails;

  return (
    <>
      <Header />
      <div className="blogPostBanner"></div>
      <div dangerouslySetInnerHTML={{ __html: content }} />
      <Footer />
    </>
  );
};

export default BlogPost;
